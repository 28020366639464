/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { formatMoney, identity } from '@bonitour/common-functions'
import { tooltipContentContainerCss } from '../TariffTooltipContent.js'
import { Manager, Reference, Tooltip } from '@bonitour/components'
import { marginLeft } from 'assets/styles/global'
import { ActivityRowTooltip } from './ActivityRowTooltip'
import { TooltipContentRow, applyTooltipStyle } from './ActivityRow.styles'

const marginLeft22 = marginLeft(22)

export const ActivityRow = ({
  id = '',
  name = '',
  color = '',
  fees = [],
  biggestFeePricing = '0',
  isVisiblePriceDetailsTooltip = false,
  onTogglePriceDetailsTooltip = identity
}) => {
  return (
    <TooltipContentRow bgColor={color}>
      <span className={'limit-size'}>{name}</span>
      <Manager>
        <Reference>
          {(({ ref }) => (
            <div ref={ref} className='price-container clickable' onClick={onTogglePriceDetailsTooltip}>
              {formatMoney(biggestFeePricing)}
            </div>
          ))}
        </Reference>
        {isVisiblePriceDetailsTooltip
          ? <Tooltip position='right' css={[applyTooltipStyle(color), marginLeft22]}>
            <div css={tooltipContentContainerCss}>
              {fees.map((feeData, feeIndex) => (
                <ActivityRowTooltip key={`fee-row-#${feeIndex}+${id}`} feeData={feeData} />
              ))}
            </div>
          </Tooltip>
          : null}
      </Manager>
    </TooltipContentRow>
  )
}
