/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useMemo } from 'react'
import { HeaderPage, Card } from '@bonitour/components'
import { useParams } from 'react-router-dom'
import { PartnershipGroupForm, viewPartnershipGroupSchema } from 'app/PartnershipGroup/form'
import { usePartnershipGroup } from './hooks/usePartnershipGroup'
import { stringifyObject } from 'utils/object'
import { usePartnershipGroupForm } from 'app/PartnershipGroup/hooks/usePartnershipGroupForm'

export const ViewPartnershipGroup = () => {
  const { groupId } = useParams()

  const {
    groupData,
    isLoadingFetch,
    onSafeBack,
    fetchGroup
  } = usePartnershipGroup(groupId)

  const formData = useMemo(() => {
    if (isLoadingFetch) return {}
    const stringifiedFormKeys = stringifyObject(groupData)
    return stringifiedFormKeys
  }, [groupData, isLoadingFetch])

  const {
    formUtils,
    updatedFormState
  } = usePartnershipGroupForm(viewPartnershipGroupSchema, formData)

  useEffect(() => {
    fetchGroup(groupId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId])

  return (
    <>
      <HeaderPage onBack={onSafeBack} title='Visualização de grupo' />
      <Card>
        <PartnershipGroupForm
          canDisableAllForm
          allowedSteps={[1, 2]}
          isLoading={isLoadingFetch}
          formState={updatedFormState}
          formUtils={formUtils}
        />
      </Card>
    </>
  )
}
