/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { usePartnershipInvite } from './hooks/usePartnershipInvite'

import { PartnershipInvite } from 'containers/PartnershipGroup/Partnership/PartnershipInvite/PartnershipInvite'
import { HeaderPage } from '@bonitour/components'

export const PartnershipInvitation = () => {
  const {
    onAcceptanceInvite,
    onRefusalInvite,
    inviteInformation,
    onBackClick,
    isLoadingSomeAction
  } = usePartnershipInvite()

  return (
    <>
      <HeaderPage title={'Convite de grupo'} onBack={onBackClick}/>
      <PartnershipInvite
        inviteInformation={inviteInformation}
        onAcceptance={onAcceptanceInvite}
        onRefusal={onRefusalInvite}
        isLoadingSomeAction={isLoadingSomeAction}
      />
    </>
  )
}
