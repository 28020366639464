import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { EXPERIENCE_VACANCY_BOX_SHADOW } from 'constants/bookingMap'

import { hexToRgba } from 'utils/hexToRgba'

export const experienceGuideRoot = css`
  display: flex;
  margin-right: 1.25rem;
  align-items: center;
`

export const experienceGuideContainer = css`
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 2rem;
  gap: 0.15rem;
  margin-right: 0.625rem;
  justify-content: end;
`

export const ExperienceGuide = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.25rem;
  ${({ color, opacity }) => (color && opacity)
    ? `
    background-color: ${hexToRgba(color, opacity)};
  `
    : ''}
  ${({ canPaintBorder, color, opacity }) => canPaintBorder
    ? `
    background-color: transparent;
    box-shadow: ${EXPERIENCE_VACANCY_BOX_SHADOW} ${hexToRgba(color, opacity)};
  `
    : ''}
`
