/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useMemo } from 'react'

import { experienceGuideContainer, ExperienceGuide, experienceGuideRoot } from './experienceGuideItem.styles'
import { hidden } from 'assets/styles/global'

export const ExperienceGuideItem = ({
  colors = [],
  opacity = 1,
  isBorderColorful = false,
  children
}) => {
  const experiencesQuantity = useMemo(() => {
    const length = colors.length
    if (length >= 4) return 4
    return length
  }, [colors])

  return (
    <div css={[experienceGuideRoot, !experiencesQuantity && hidden]}>
      <div css={experienceGuideContainer}>
        {Array.from({ length: experiencesQuantity }).map((_, index) => (
          <ExperienceGuide
            key={`experience-guide-${index}-${colors[index]}`}
            color={colors[index]}
            opacity={opacity}
            canPaintBorder={isBorderColorful}
          />
        ))}
      </div>
      {children}
    </div>
  )
}
