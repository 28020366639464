/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

import { CircleThumbnail, flexHorizontallyCentered } from '@bonitour/components'

import { marginLeft } from 'assets/styles/global'

const marginLeft0 = marginLeft(0)

const thumbnailCss = css`
  width: 0.875rem;
  height: 0.875rem;
  margin: 0 0.625rem;
  box-shadow: 0px 1px 0.188rem #00000029;
`

const mapGuideIconCss = css`
  width: 0.875rem;
  height: 0.875rem;
  margin: 0 0.625rem;
`

export const MapGuideItem = ({ color, icon: Icon = null, children, canRemoveMarginLeft = false }) => (
  <div css={flexHorizontallyCentered}>
    {Boolean(color) && <CircleThumbnail css={thumbnailCss} size={14} style={{ backgroundColor: color }} />}
    {Boolean(Icon) && <Icon css={[mapGuideIconCss, canRemoveMarginLeft && marginLeft0]} />}
    <span>{children}</span>
  </div>
)
