/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'

import { useCallback, useMemo, useState } from 'react'
import { useActivity } from 'contexts/Activity'

import { identity } from '@bonitour/common-functions'
import { colors, Fab, H1, H3, Input, InputFormGroup, Row, Select, useToast } from '@bonitour/components'

const activityContainerCss = css`
  margin-bottom: 2rem;
`

const fabCss = css`
  height: auto;
`

const paragraphInfoCss = css`
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: ${colors.gray2};

  b {
    font-weight: bolder;
    color: ${colors.red1};
}
`

const actionRowCss = css`
  justify-content: flex-start;
  gap: 2rem;

  > div {
    width: auto;
    flex-grow: initial;
  }
`

const containerCss = css`
  margin-top: 2rem;
`

const GuideBox = styled.div`
  display: inline-block;
  background-color: ${({ color }) => color};
  width: 0.85rem;
  height: 0.85rem;
  border-radius: 0.200rem;
  margin-right: 0.5rem;
`

// TO DO: remove the test mod
export const BookingMapTestMode = ({
  onSelectActivity: emitSelectActivity = identity,
  activitiesRegistryAndPrice,
  activitiesColors = []
}) => {
  const { companyActivities } = useActivity()
  const { add: addToast } = useToast()

  const [moutingActivitiesSelected, setMoutingActivitiesSelected] = useState({})

  const getMountingActivityInfo = useCallback((activityId) => {
    const hour = Object.keys(moutingActivitiesSelected?.[activityId] || {})[0]
    const date = Object.keys(moutingActivitiesSelected?.[activityId]?.[hour] || {})[0]
    const quantity = moutingActivitiesSelected?.[activityId]?.[hour]?.[date] || 0

    return {
      date,
      hour,
      quantity
    }
  }, [moutingActivitiesSelected])

  const activitiesInfo = useMemo(() => {
    return companyActivities.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr
      }
    }, {})
  }, [companyActivities])
  const getHourliesOptions = useCallback((activityIndex) => {
    const activityData = activitiesRegistryAndPrice?.[activityIndex]
    if (!activityData) return []

    return Object.keys(activityData.registries)
      .map((hour) => ({
        label: hour,
        value: hour
      }))
  }, [activitiesRegistryAndPrice])
  const getDateOptions = useCallback((hourly, activityIndex) => {
    const activityData = activitiesRegistryAndPrice?.[activityIndex]
    if (!activityData) return []
    return Object.keys(activityData.registries[hourly])
      .map((date) => ({
        label: date,
        value: date
      }))
  }, [activitiesRegistryAndPrice])
  const onSelectHour = useCallback((hour, activityId) => {
    setMoutingActivitiesSelected((prev) => ({
      ...prev,
      [activityId]: {
        [hour]: {}
      }
    }))
  }, [])
  const onSelectDate = useCallback((date, activityId) => {
    const {
      hour
    } = getMountingActivityInfo(activityId)

    return setMoutingActivitiesSelected((prev) => ({
      ...prev,
      [activityId]: {
        ...(prev?.[activityId] || {}),
        [hour]: {
          [date]: {}
        }
      }
    }))
  }, [getMountingActivityInfo])
  const onSelectQuantity = useCallback((quantity, activityId) => {
    const {
      date,
      hour
    } = getMountingActivityInfo(activityId)

    return setMoutingActivitiesSelected((prev) => ({
      ...prev,
      [activityId]: {
        ...(prev?.[activityId] || {}),
        [hour]: {
          ...(prev?.[activityId]?.[hour] || {}),
          [date]: quantity
        }
      }
    }))
  }, [getMountingActivityInfo])

  const onSubmitActivity = useCallback((activityId) => {
    const activityData = getMountingActivityInfo(activityId)

    emitSelectActivity({
      id: activityId,
      feeName: '0x29a-fee',
      ...activityData
    })

    setMoutingActivitiesSelected(prev => {
      const newSelected = { ...prev }

      delete newSelected[activityId]

      return newSelected
    })

    addToast('Atividade selecionada com sucesso!', 'success')
  }, [addToast, emitSelectActivity, getMountingActivityInfo])

  return (
    <div css={containerCss}>
      <H1>Simular seleção de vagas</H1>
      <p css={paragraphInfoCss}>
        <b>Atenção</b>: esse modo de teste foi criado temporariamente apenas para simular a adição de vagas selecionadas. Atenção na hora de selecionar a atividade, horário e data, pois não tem filtro de data e horário por atividade, para deixar esse teste o mais simples e leve possível.<br/><br/>
        <b>*</b> De acordo com o outro desenvolvedor, o componente era para suportar até 999 vagas diponíveis, 99 vagas selecionadas e 999 vagas já consumidas (999|99|999), então caso ultrapasse alguma dessas grandezas, o layout pode quebrar.
      </p>
      {Object.keys(activitiesRegistryAndPrice).map(activityIndex => {
        const activityId = activitiesRegistryAndPrice[Number(activityIndex)]?.id
        const activityInfo = activitiesInfo?.[activityId]

        const {
          date: dateValue,
          hour: hourValue,
          quantity: quantityValue
        } = getMountingActivityInfo(activityId)

        return <div key={`map-edition-${activityId}`} css={activityContainerCss}>
          <H3>
            <GuideBox color={activitiesColors[activityIndex]}/>
            Atividade: {activityInfo?.name || 'N/A'}
          </H3>
          <Row css={actionRowCss}>
            <InputFormGroup label='Horário' horizontal>
              <Select options={getHourliesOptions(Number(activityIndex))} onChange={value => onSelectHour(value, activityId)} value={hourValue} />
            </InputFormGroup>
            {hourValue && <InputFormGroup label='Data' horizontal>
              <Select options={getDateOptions(hourValue, (Number(activityIndex)))} onChange={value => onSelectDate(value, activityId)} value={dateValue}/>
            </InputFormGroup>}
            {dateValue && <InputFormGroup label='Quantidade de vagas' horizontal>
              <Input type="number" min={1} onChange={value => onSelectQuantity(Number(value), activityId)} value={quantityValue}/>
            </InputFormGroup>}
            {Boolean(quantityValue) && <Fab onClick={() => onSubmitActivity(activityId)} css={fabCss}>+</Fab>}
          </Row>
        </div>
      })}
    </div>
  )
}
