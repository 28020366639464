/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useCallback, useEffect } from 'react'

import { useFeatureFlag } from 'contexts/Feature'

import { CustomCommissions } from './CustomCommissions/CustomCommissions'

import { H3, HorizontalInputFormGroup, Input, InputWithSuffix, ToggleInputGroup } from '@bonitour/components'

import { grayColor, marginBottom10, marginBottom20, marginLeft10, marginTop10, marginTop20, sellViaAllyContainer, width110, inverseRow } from './TradeAgreement.styles'

export const TradeAgreement = ({
  formUtils = {},
  tradeAgreement = {},
  errors = {},
  savedCustomCommissions = [],
  isLoading = false,
  isGroupEditable = false,
  isFormDisabled = false,
  canDisableAllForm = false,
  submitCount = 0
}) => {
  const {
    onChange,
    register
  } = formUtils

  const [isNetValueEnabled] = useFeatureFlag('orb-net-value')
  const [isSaleViaAllayEnabled] = useFeatureFlag('orb-sell-via-ally')

  const {
    name = '',
    commissioning = '',
    sellViaAlly = true,
    netFeeEnabled = false
  } = tradeAgreement

  const toggleSellViaAlly = useCallback(() => onChange('tradeAgreement.sellViaAlly')(!sellViaAlly), [onChange, sellViaAlly])
  const toggleNetFee = useCallback(() => onChange('tradeAgreement.netFeeEnabled')(!netFeeEnabled), [onChange, netFeeEnabled])

  const isNonCommissionGroup = (name === 'no_commission_group' || name === 'Meu grupo')

  useEffect(() => {
    if (isNonCommissionGroup) onChange('tradeAgreement.name')('Meu grupo')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  return (
    <div>
      <>
        <H3 css={marginBottom20}>Nome do grupo</H3>
        <HorizontalInputFormGroup errorMessage={errors?.name?.message}>
          <Input
            disabled={(!isGroupEditable || isFormDisabled || isLoading) || canDisableAllForm}
            onChangeWholeEvent={true}
            {...register('tradeAgreement.name')}
          />
        </HorizontalInputFormGroup>
      </>

      <H3 css={[marginTop20, marginBottom10]}>Comissionamento padrão</H3>

      {isNetValueEnabled
        ? (
          <div>
            <HorizontalInputFormGroup errorMessage={errors?.netFeeEnabled?.message}>
              <ToggleInputGroup
                disabled={isFormDisabled || isLoading || canDisableAllForm}
                onChange={toggleNetFee}
                checked={netFeeEnabled}
              />
            </HorizontalInputFormGroup>
            <span css={grayColor}>Tarifa Net</span>
          </div>
        )
        : null}

      <span css={grayColor}>A porcentagem será de</span>
      <HorizontalInputFormGroup errorMessage={errors?.commissioning?.message}>
        <InputWithSuffix
          min='0'
          type='number'
          disabled={(!isGroupEditable || isFormDisabled || isLoading || canDisableAllForm)}
          css={[marginTop10, marginLeft10, width110]}
          onChangeWholeEvent={true}
          {...register('tradeAgreement.commissioning')}
        >%
        </InputWithSuffix>
      </HorizontalInputFormGroup>

      {!isLoading
        ? (
          <CustomCommissions
            initialData={savedCustomCommissions}
            onChange={onChange('customCommissions')}
            baseCommission={commissioning}
            isNetFeeDefault={netFeeEnabled}
            isDisabled={isFormDisabled || isLoading || canDisableAllForm}
            isGroupEditable={isGroupEditable}
            submitCount={submitCount}
          />
        )
        : null}

      {
        isSaleViaAllayEnabled
          ? (
            <>
              <H3 css={[marginTop20, marginBottom20]}>Venda no aplicativo Ally</H3>
              <div css={sellViaAllyContainer}>
                <HorizontalInputFormGroup errorMessage={errors?.sellViaAlly?.message}>
                  <ToggleInputGroup
                    disabled={isFormDisabled || isLoading || canDisableAllForm}
                    onChange={toggleSellViaAlly}
                    checked={sellViaAlly}
                    canEmitToggleEventOfRow={true}
                    customCss={[inverseRow]}>
                      Permitir a venda no aplicativo Ally
                  </ToggleInputGroup>
                </HorizontalInputFormGroup>
              </div>
            </>
          )
          : null}
    </div>
  )
}
