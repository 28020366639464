import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { colors } from '@bonitour/components'

export const TooltipContentRow = styled.div`
  width: 20rem;
  color: ${colors.white};
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(prop) => prop.bgColor};
  border-radius: 6px;
  height: 2.5rem;
  span, p {
    margin-left: 0.65rem;
    -webkit-text-stroke: 2px rgba(0, 0, 0, 0.25);
    paint-order: stroke fill;
    font-weight: bolder;
    font-size: 1.05rem;
  }
  .limit-size {
    max-width: 15rem;
    white-space: nowrap;
    overflow: hidden;
    line-height: 2rem;
    padding-right: 0.45rem;
    text-overflow: ellipsis;
  }
  .price-container {
    display: flex;
    place-content: center;
    place-items: center;
    background-color: ${colors.white};
    color: ${colors.gray2};
    height: 1rem;
    border-radius: 6px;
    font-weight: bolder;
    margin-right: 0.65rem;
    padding: 0.25rem 0.90rem 0.25rem 0.90rem;
    &.clickable {
      cursor: pointer;
      user-select: none;
    }
  }
  [class*='TooltipArrow']::before {
    border-color: transparent ${prop => prop.bgColor} transparent transparent;
  }
`

export const applyTooltipStyle = (bgColor) => css`
  background-color: ${bgColor};
  padding: 0;
  margin-left: 1rem;
`
