import { COMBINED_EXPERIENCE_TYPE } from 'constants/activityTypes'
import { getImageUrl } from 'services/Orb/Service'

const service = ({
  id,
  experience_id: experienceId,
  service_id: serviceId,
  optional: isOptional,
  company_id: companyId,
  service = {}
}) => {
  const {
    service_id: destructuredServiceId,
    service_image: serviceImage = null,
    service_title: serviceTitle
  } = service

  return {
    id,
    experienceId,
    serviceId: destructuredServiceId || serviceId,
    isOptional,
    companyId,
    serviceImage: getImageUrl(serviceImage),
    serviceTitle
  }
}

export const CombinedExperienceParser = {
  pagination: ({
    total_entries,
    current_page,
    total_pages
  }) => ({
    totalEntries: total_entries,
    currentPage: current_page,
    totalPages: total_pages
  }),
  experience: ({
    id,
    company_id: companyId,
    company_name: companyName = null,
    title,
    subtitle,
    description,
    enabled: isEnabled,
    services_has_to_be_on_same_day: isSameDayServices,
    experiences_services: experiences = [],
    vendor_id: vendorId,
    vendor_name: vendorName
  }) => ({
    id,
    companyId: companyId || vendorId,
    companyName: companyName || vendorName,
    name: title,
    subtitle,
    description,
    isEnabled,
    isSameDayServices,
    type: COMBINED_EXPERIENCE_TYPE,
    experiences: experiences.map(service)
  }),
  experienceToForm: ({
    id,
    name,
    subtitle,
    description,
    experiences = [],
    isEnabled,
    isSameDayServices
  }) => ({
    id,
    title: name,
    subtitle,
    description,
    experiences: experiences.map((data) => ({
      id: data.serviceId,
      isOptional: data.isOptional
    })),
    isEnabled,
    isSameDayServices
  }),
  allExperiences: ({
    experiences = [],
    meta
  }) => ({
    experiences: experiences.map(CombinedExperienceParser.experience),
    meta: CombinedExperienceParser.pagination(meta)
  }),
  extractExperience: (data, isForEdition = false) => {
    const {
      experience = {}
    } = data

    const parsedData = CombinedExperienceParser.experience(experience)

    if (!isForEdition) return parsedData

    return CombinedExperienceParser.experienceToForm(parsedData)
  }
}
