import { css } from '@emotion/core'

export const summaryContainer = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

export const vacancyTableBodyCell = css`
  padding: 0.5rem;
`
