/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { useHover } from 'hooks/useHover'
import { colors, Fab, flexRow, Row } from '@bonitour/components'
import { TariffTooltip } from './components/TariffTooltipCombinedExperience/TariffTooltip'

const rowCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    text-align: center;
    width: 7rem;
    margin: 0 1rem 0 1rem;
    font-size: 1.15rem;
    font-weight: bold;
    color: ${colors.gray2};
  }
`

const centeredCss = css`
  height: 600px;
  place-content: center;
  place-items: center;
  flex-direction: column;
  gap: 1rem;
`

const Box = styled.div`
  border-radius: 0.45rem;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-weight: bolder;
  font-size: 2rem;
  color: ${colors.white};
  background-color: ${colors.gray7};
  border: 2px solid ${colors.gray4};
  height: 4rem;
  width: 4rem;
  display: flex;
  place-content: center;
  place-items: center;
  padding: 0;
`

const colorsC = [colors.indigo2, colors.green7, colors.blue4, colors.orange1, colors.indigo1, colors.green2, colors.orange4, colors.red2]

const data = [
  {
    name: 'Bote no Rio Formoso',
    color: colorsC[0],
    id: 1,
    biggerFee: '2685.72',
    fees: [
      {
        name: 'Adulto',
        pricing: '2685.72',
        minAge: 12,
        maxAge: 49
      },
      {
        name: 'Não pagante',
        pricing: '65.0',
        minAge: 1,
        maxAge: 7
      }
    ]
  },
  {
    name: 'Atividade C',
    color: colorsC[2],
    id: 3,
    biggerFee: '120.0',
    fees: [
      {
        name: 'Tarifa Única',
        pricing: '120.0',
        minAge: 18,
        maxAge: 120
      }
    ]
  },
  {
    name: 'Atividade D',
    color: colorsC[7],
    id: 4,
    biggerFee: '99.0',
    fees: [
      {
        name: 'Adulto',
        pricing: '85.72',
        minAge: 12,
        maxAge: 49
      },
      {
        name: 'Criança',
        pricing: '99.0',
        minAge: 1,
        maxAge: 7
      }
    ]
  },
  {
    name: 'Atividade Da',
    color: colorsC[3],
    id: 43,
    biggerFee: '99.0',
    fees: [
      {
        name: 'Adulto',
        pricing: '85.72',
        minAge: 12,
        maxAge: 49
      },
      {
        name: 'Criança',
        pricing: '99.0',
        minAge: 1,
        maxAge: 7
      }
    ]
  },
  {
    name: 'Atividade Chaves',
    color: colorsC[1],
    id: 42,
    biggerFee: '99.0',
    fees: [
      {
        name: 'Adulto',
        pricing: '85.72',
        minAge: 12,
        maxAge: 49
      },
      {
        name: 'Criança',
        pricing: '99.0',
        minAge: 1,
        maxAge: 7
      }
    ]
  },
  {
    name: 'Atividade Kacas',
    color: colorsC[4],
    id: 23,
    biggerFee: '99.0',
    fees: [
      {
        name: 'Adulto',
        pricing: '85.72',
        minAge: 12,
        maxAge: 49
      },
      {
        name: 'Criança',
        pricing: '99.0',
        minAge: 1,
        maxAge: 7
      }
    ]
  },
  {
    name: 'Atividade Buscape',
    color: colorsC[5],
    id: 12,
    biggerFee: '99.0',
    fees: [
      {
        name: 'Adulto',
        pricing: '85.72',
        minAge: 12,
        maxAge: 49
      },
      {
        name: 'Criança',
        pricing: '99.0',
        minAge: 1,
        maxAge: 7
      }
    ]
  }
]

export const TariffCombinedExperienceTest = () => {
  const [isVisible, showOnHover, hideOnHover] = useHover()
  const [limitOfActivites, setLimitOfActivities] = useState(1)

  const activitiesData = useMemo(() => data.slice(0, limitOfActivites), [limitOfActivites])

  const toggleLimitOfActivities = useCallback((isForSum = false) => () => {
    setLimitOfActivities((prev) => {
      if (isForSum) {
        if (prev + 1 > (data.length - 1)) {
          return prev
        }
        return prev + 1
      }
      if (prev - 1 < 1) {
        return prev
      }
      return prev - 1
    })
  }, [])

  const currentDay = useMemo(() => moment().format('DD'), [])

  return <>
    <Row css={rowCss}>
      <Fab onClick={toggleLimitOfActivities()}>-</Fab>
      <span>
        {limitOfActivites} atividade{limitOfActivites > 1 ? 's' : ''}
      </span>
      <Fab onClick={toggleLimitOfActivities(true)}>+</Fab>
    </Row>
    <div css={[flexRow, centeredCss]}>
      <div onMouseLeave={hideOnHover} onMouseOver={showOnHover}>
        <TariffTooltip isVisible={isVisible} activitiesPricingData={activitiesData}>
          <Box>
            {currentDay}
          </Box>
        </TariffTooltip>
      </div>
    </div>
  </>
}
