/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useMemo } from 'react'
import { ErrorTooltip } from '@bonitour/components'
import { floatTooltip, inlineElement, sideError, centerOnBottom, topLeftTooltipArrow, normalWidth } from './PartnershipGroupError.styles'
import { hidden, positionRelative } from 'assets/styles/global'

export const PartnershipGroupError = ({
  error = undefined,
  customCss = [],
  isTooltip = true,
  canShowOnBottom = false,
  isVisible = true,
  isRawError = false,
  children
}) => {
  const hasError = useMemo(() => !!error, [error])
  return (
    <div
      css={[inlineElement, positionRelative, !isTooltip && centerOnBottom, !isVisible && hidden]}
      className={[canShowOnBottom && 'bottom'].join(' ')}>
      {children}
      <ErrorTooltip
        css={[
          isTooltip ? floatTooltip : sideError,
          !error && hidden,
          ...(canShowOnBottom ? [topLeftTooltipArrow, normalWidth] : []),
          ...customCss
        ]}
        className={[
          hasError && (isRawError ? error : error?.message?.length > 50) && 'more-space'
        ].join(' ')}>
        {(isRawError ? error : error?.message) || ''}
      </ErrorTooltip>
    </div>
  )
}
