/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

import { TooltipOnHover } from '@bonitour/components'

const tooltipCss = css`
  white-space: nowrap;
`

const restrictionGuideIconCss = css`
  font-size: 1.625rem;
  margin: 0 0.625rem;
`

export const RestrictionGuideItem = ({ icon: Icon = null, tooltip = '' }) => (
  <TooltipOnHover css={tooltipCss} text={tooltip}>
    <Icon css={restrictionGuideIconCss} />
  </TooltipOnHover>
)
