import { css } from '@emotion/core'
import { BREAK_POINTS } from '@bonitour/components'

export const topLeftTooltipArrow = css`
  width: auto;
  margin-top: 0.45rem;
  &::after {
    top: 0;
    left: 3%;
    transform: translateX(-3%) translateY(-99%) rotate(180deg);
  }
`

export const floatTooltip = css`
  display: block;
  position: absolute;

  height: auto;
  width: 12rem;
  top: -4.30rem;
  left: 0;
  white-space: break-spaces;

  &.more-space {
    width: 10rem;
    top: -5.20rem;
  }

  @media screen and (max-width: ${BREAK_POINTS.smallTablet}) {
    top: -3.30rem;
  }
`

export const centerOnBottom = css`
  display: inline-flex;
  place-content: flex-start;
`

export const normalWidth = css`
  width: max-content;
`

export const sideError = css`
  top: 0 !important;
  left: 0 !important;
  margin-left: 0.25rem;
  position: relative;

  &::after {
    left: -0.580rem;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
  }

  @media screen and (max-width: ${BREAK_POINTS.smallTablet}) {
    ${topLeftTooltipArrow}
  }
`

export const inlineElement = css`
  display: inline;
  margin-top: 0;
  margin-bottom: 0;

  &.bottom {
    display: inline-flex;
    flex-direction: column;
  }

  @media screen and (max-width: ${BREAK_POINTS.smallTablet}) {
    display: inline-flex;
    flex-direction: column;
  }
`
