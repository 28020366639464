/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useState } from 'react'
import { Dialog } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { ExperienceListSelector } from 'containers/Activity/ListSelector/ListSelector'
import { dialogContainer } from './ExperienceSelectorModal.style'

export const ExperienceSelectorModal = ({
  children,
  onExperienceSelected = identity,
  customCss = [],
  subdomain = null,
  disabledTypes = [],
  fromCurrentCompany = undefined,
  isDisabledOnClick = false,
  ...other
}) => {
  const [isSelectorVisible, setVisibility] = useState(false)
  const onSelectorOpen = useCallback(() => setVisibility(true), [])
  const onSelectorClose = useCallback(() => setVisibility(false), [])

  const onOptionClick = useCallback((id, type) => {
    onSelectorClose()
    onExperienceSelected(id, type)
  }, [onExperienceSelected, onSelectorClose])

  return (
    <>
      <div css={[...customCss]} {...other}>
        <div onClick={isDisabledOnClick ? identity : onSelectorOpen}>{children}</div>
      </div>
      <Dialog customContainercss={[dialogContainer]} title='Selecione uma experiência' isVisible={isSelectorVisible} onClose={onSelectorClose}>
        <ExperienceListSelector onSelectedExperience={onOptionClick} subdomain={subdomain} disabledTypes={disabledTypes} fromCurrentCompany={fromCurrentCompany}/>
      </Dialog>
    </>
  )
}
