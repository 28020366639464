/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useHover } from 'hooks/useHover'

import { BookingCard } from 'domains/Booking/Map/Card/BookingCard'
import { ReservationDetails } from '../../../Guides/ReservationDetails'
import { Reference, Manager } from '@bonitour/components'

export const DetailedBooking = ({ totalReservations, reservationDetails, ...other }) => {
  const [isVisible, showDetails, hideDetails] = useHover(300)

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div
            ref={ref}
            onMouseOver={showDetails}
            onMouseOut={hideDetails}
          >
            <BookingCard disabled={!totalReservations} {...other}>
              {totalReservations}
            </BookingCard>
            {isVisible && <ReservationDetails reservationDetails={reservationDetails} />}
          </div>
        )}
      </Reference>
    </Manager>
  )
}
