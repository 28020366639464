/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { TableBodyCell } from '@bonitour/components'

import { summaryContainer, vacancyTableBodyCell } from './summaryContainer.styles'

export const SummaryContainer = ({ children }) => {
  return (
    <TableBodyCell css={vacancyTableBodyCell}>
      <div css={summaryContainer}>{children}</div>
    </TableBodyCell>
  )
}
