import { css } from '@emotion/core'

import { BREAK_POINTS, flexCenteredSpaceBetweenRow } from '@bonitour/components'

export const ghostStyling = css`
  margin-right: 20px;
  padding: 12px 30px;
`

export const ctaPadding = css`
  padding: 12px 40px;
`

export const buttonsContainer = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    display: flex;
    flex-direction: column;
    width: 100%;

    button {
      width: 100%;
      margin: 10px 0;
    }
  }
`

export const headerContainer = css`
  ${flexCenteredSpaceBetweenRow};

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: column;
  }
`

export const ghostButtonDisabled = css`
  &:disabled {
    opacity: 0.25;
  }
`
