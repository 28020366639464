export const sellersGroupsModel = ({
  id = '',
  tradeAgreement = {},
  invoicePolicy = {},
  comercialPartners = [],
  customCommissions = [],
  editable = true
}) => {
  const {
    name,
    commissioning,
    sellViaAlly,
    netFeeEnabled
  } = tradeAgreement

  const {
    invoiced,
    deadline,
    deadlineType,
    closingPeriod,
    closingDay,
    paymentDay,
    invoice: {
      noShow
    }
  } = invoicePolicy

  const deadlineInfo = deadline
    ? { deadline, deadline_type: deadlineType }
    : { deadline: null }

  const invoiceInfo = {
    payment_guarantee: { no_show: invoiced ? noShow : false }
  }

  const commissioningByService = customCommissions.reduce((acc, curr) => ({
    ...acc,
    [curr.service]: {
      commission: Number(curr.commission),
      net_fee_enabled: curr.netFeeEnabled
    }
  }), {})

  return {
    id,
    comercial_partner_ids: comercialPartners,
    sell_via_ally: sellViaAlly,
    ...(editable
      ? {
        name,
        net_fee_enabled: netFeeEnabled,
        commissioning,
        commissioning_by_service: commissioningByService,
        sell_via_ally: sellViaAlly,
        ...deadlineInfo,
        invoice_policy: {
          invoiced,
          invoice_closing_period: closingPeriod,
          invoice_closing_day: closingDay,
          payment_day: paymentDay,
          ...invoiceInfo
        }
      }
      : {})
  }
}
