import { css } from '@emotion/core'
import { BREAK_POINTS, colors } from '@bonitour/components'
import { marginBottom, marginTop } from 'assets/styles/global'

export const cardStyle = css`
  margin: 10px 0;
  padding: 30px 20px;
`

export const selectStyle = (value = 100) => css`
  input {
    width: ${value}px;
  }
`

export const marginSide = css`
  margin: 0 10px;
`

export const grayColor = css`
  color: ${colors.gray3};
`

export const boldText = css`
  font-weight: bold;
`

export const horinzontalMargin10 = css`
  margin: 0 10px;
`

export const width110 = css`
  width: 110px;
`

export const line = css`
  > span, div {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  display: grid !important;
  grid-template-columns: auto auto auto;
  justify-content: flex-start !important;

  @media screen and (max-width: 1140px) {
    grid-template-columns: auto auto;
    .has-error {
      padding-top: 2.65rem;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.smallTablet}) {
    grid-template-columns: auto;
    .has-error {
      padding-top: 2rem;
    }
    .no-has-error {
      padding-top: 0 !important;
    }
  }
`

export const radioGroup = css`
  line-height: 1.5;
`

export const moreContentTooltip = css`
  width: max-content;
  top: 0.5rem;
  left: 12rem;
`

export const selectMarginDisabled = css`
  div {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
`

export const radioErrorTheme = css`
  border: 1px solid ${colors.red1};
`

export const normalWidth = css`
  width: max-content;
`

export const marginBottom20 = marginBottom(20)
export const marginTop20 = marginTop(20)
export const marginTop30 = marginTop(30)
export const selectStyle100 = selectStyle(100)
export const selectStyle30 = selectStyle(30)
