/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { effect } from '@preact/signals-core'
import { createContext, useEffect, useMemo, useState } from 'react'
import { selectedActivities } from '../bookingMapStates'

export const BookingMapInfoCtx = createContext()

export const BookingMapInfoProvider = ({ children }) => {
  const [loadedSelectedActivities, setSelectedActivities] = useState({})

  useEffect(() => {
    const signalsEffect = effect(() => {
      setSelectedActivities(selectedActivities.value)
    })
    return () => signalsEffect()
  }, [])

  const value = useMemo(() => ({
    loadedSelectedActivities
  }), [loadedSelectedActivities])

  return <BookingMapInfoCtx.Provider value={value}>{children}</BookingMapInfoCtx.Provider>
}
