import { SafeDate } from '@binamik/js-providers'
import { metaModel } from './meta'

/* eslint-disable camelcase */
export const MyDayModel = {
  list: ({ filters = {}, pagination = {} }) => {
    const {
      ticketCode: ticket_code,
      passengerName: passenger_name,
      date,
      servicesId: service_id,
      partnerId: partner_id,
      providerId: provider_id,
      sellerId: seller_id,
      cities = [],
      states = [],
      countries = [],
      ticketStatus: state = []
    } = filters

    const hasAddressData = cities.length || states.length || countries.length

    const meta = metaModel(pagination)

    const searchParams = {
      date: MyDayModel.parseSafeDate(date),
      ticket_code,
      passenger_name,
      partner_id,
      service_id,
      seller_id,
      provider_id,
      state,
      address: hasAddressData
        ? {
          city_id: cities,
          state_id: states,
          country_id: countries
        }
        : {}
    }

    return { searchParams, pagination: meta }
  },
  myDayExport: ({ filters = {}, pagination = {} }) => {
    const {
      ticketCode: ticket_code,
      passengerName: passenger_name,
      date,
      serviceId: service_id,
      partnerId: partner_id,
      wantedFields: wanted_fields
    } = filters

    const meta = metaModel(pagination)

    const searchParams = {
      date: MyDayModel.parseSafeDate(date),
      ticket_code,
      passenger_name,
      partner_id,
      service_id: service_id ? [service_id] : undefined,
      wanted_fields
    }

    return { searchParams, pagination: meta }
  },
  parseSafeDate: (date) => {
    return new SafeDate(date).format('YYYY-MM-DD')
  }
}
