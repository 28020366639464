export const calculateHouliesAvailable = (registriesAndPrice) => {
  const allHourlies = registriesAndPrice
    .flatMap(({ registries }) => Object.keys(registries))

  return [...new Set(allHourlies)].sort((a, b) => {
    const [hourA, minuteA] = a.split(':').map(Number)
    const [hourB, minuteB] = b.split(':').map(Number)

    if (hourA === hourB) {
      return minuteA - minuteB
    }
    return hourA - hourB
  })
}

export const hasSelectedActivityFilter = ({ vacancies }) => vacancies.selectedTotals > 0

export const canHideActivityFilter = (value) => Boolean(value)

/**
 * @typedef {Object.<string, Object.<string, Object.<string, number>>>} VacanciesOccupied
 *
 * @property {Object.<string, Object.<string, number>>} [resourceId] - Unique activity ID.
 * @property {Object.<string, number>} [resourceId.time] - Specific time slot.
 * @property {number} [resourceId.time.date] - Number of available slots for the given date.
 *
 * @example
 * const VacanciesOccupied = {
 *   "f9eefa96-1f16-4f95-b0bf-c23dd31984f9": {
 *     "08:00": {
 *       "2024-10-31": 100,
 *       "2024-11-02": 99
 *     }
 *   }
 * };
 */

/**
 * @param {VacanciesOccupied|null} activityTotalsData
 * @param {string} hour
 * @returns {Function}
 */
export const filterInvalidActivitiesAndFillData = (acc, [currentDate, currentData]) => {
  const {
    reservations,
    vacancies
  } = currentData

  const canHideDate = (!vacancies?.isAvailable || !vacancies?.total) && !reservations?.total

  if (canHideDate) return acc

  acc[currentDate] = currentData
  return acc
}
