import { BREAK_POINTS, colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const swatch = css`
  color: ${colors.white};
  font-weight: bolder;
  font-size: 1.5rem;

  height: 2.5rem;
  width: 2.5rem;
`

export const sectionHeader = css`
  display: flex;
  margin-bottom: 1.5rem;
  place-items: center;
  position: relative;

  > div {
    margin-right: 0.5rem;
  }
  > h2 {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
  }

  p {
    position: absolute;
    right: 0;
    top: 50;
    font-size: 0.75rem;
    color: ${colors.gray4};
  }

  @media screen and (max-width: ${BREAK_POINTS.tableUp}) {
    p {
      display: none;
    }
  }
`
