/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { useCallback } from 'react'
import { formatMoney } from '@bonitour/common-functions'
import { FeeRowContainer } from './ActivityRowTooltip.styles'

export const ActivityRowTooltip = ({
  feeData = {}
}) => {
  const getFeeAgeRestrictionLabel = useCallback((fee) => {
    if (fee.minAge && fee.maxAge) {
      const pluralizedLabel = fee.maxAge > 1 ? 's' : ''
      return `${fee.minAge} a ${fee.maxAge} ano${pluralizedLabel}`
    }
    return ''
  }, [])

  return (
    <FeeRowContainer>
      <section>
        <span>{feeData.name}</span>
        <p>{getFeeAgeRestrictionLabel(feeData)}</p>
      </section>
      <div className='price-container'>
        {formatMoney(feeData.pricing)}
      </div>
    </FeeRowContainer>
  )
}
