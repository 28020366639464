/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useMemo } from 'react'
import { H2, Swatch, colors } from '@bonitour/components'
import { sectionHeader, swatch } from './PartnershipSectionHeader.styles'

export const PartnershipSectionHeader = ({
  title = '',
  step = 1,
  topics = []
}) => {
  const stringifiedTopics = useMemo(() => topics.join(' / '), [topics])
  return (
    <div css={sectionHeader}>
      <Swatch color={colors.gray8} css={swatch}>{step}</Swatch>
      <H2>{title}</H2>
      <p>{stringifiedTopics}</p>
    </div>
  )
}
