/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useEffect, useCallback, useMemo } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { useSellersGroup } from './hooks/useSellersGroup'
import { usePartnershipGroupForm } from './hooks/usePartnershipGroupForm'

import { HeaderPage, Card, Button, useToast } from '@bonitour/components'
import { editPartnershipGroupSchema, PartnershipGroupForm } from './form'

import { positionRelative, fullWSize } from 'assets/styles/global'
import { PartnershipCounter } from 'containers/PartnershipGroup/Partnership/components'

const BACK_PATH = '/sellers'

export const EditPartnershipGroup = () => {
  const { add: addToast } = useToast()
  const { push } = useHistory()
  const { groupId } = useParams()
  const {
    get,
    edit,
    visualizationResponse,
    isLoadingEdition,
    isLoadingVisualization
  } = useSellersGroup()

  const isLoading = useMemo(() => isLoadingEdition || isLoadingVisualization, [isLoadingEdition, isLoadingVisualization])

  const {
    handleSubmit,
    updatedFormState,
    formUtils
  } = usePartnershipGroupForm(editPartnershipGroupSchema)

  const {
    setValue,
    watch
  } = formUtils

  const backToDashboard = useCallback(() => {
    if (!isLoadingEdition) {
      push('/sellers')
    }
  }, [isLoadingEdition, push])

  const isGroupEditable = watch('editable')

  useEffect(() => {
    get(groupId)
      .catch(() => push(BACK_PATH))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (visualizationResponse) {
      Object.keys(visualizationResponse)
        .forEach((key) => setValue(key, visualizationResponse[key]))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualizationResponse])

  const onFailedValidation = useCallback(() => {
    addToast('Confira o formulário e tente novamente')
  }, [addToast])

  const onSuccessValidation = useCallback((data) => {
    edit(data)
      .then(() => {
        push(BACK_PATH)
      })
  }, [edit, push])

  const onSubmit = handleSubmit(onSuccessValidation, onFailedValidation)

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Grupo' />
      <Card>
        <div css={positionRelative}>
          <PartnershipGroupForm
            isGroupEditable={isGroupEditable}
            formState={updatedFormState}
            isLoading={isLoading}
            canMinifyFormLayout={isLoadingVisualization}
            formUtils={formUtils}
          />
          <Button
            css={[fullWSize, positionRelative]}
            disabled={isLoading}
            onClick={onSubmit}>
            {isLoadingEdition ? 'Salvando' : 'Salvar'}
            <PartnershipCounter selectedQuantity={updatedFormState.data.comercialPartners?.length} />
          </Button>
        </div>
      </Card>
    </>
  )
}
