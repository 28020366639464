import { useCallback, useEffect } from 'react'
import { selectedActivities } from '../bookingMapStates'

export const useBookingMap = () => {
  const onSelectActivity = useCallback((activityData) => {
    if (selectedActivities.value?.[activityData.id]) {
      selectedActivities.value = {
        ...selectedActivities.value,
        [activityData.id]: {
          ...selectedActivities.value[activityData.id],
          [activityData.hour]: {
            ...(selectedActivities.value[activityData.id]?.[activityData.hour] || {}),
            [activityData.date]: {
              ...(selectedActivities.value[activityData.id]?.[activityData.hour]?.[activityData.date] || {}),
              [activityData.feeName]: activityData.quantity
            }
          }
        }
      }
    } else {
      selectedActivities.value = {
        ...selectedActivities.value,
        [activityData.id]: {
          [activityData.hour]: {
            [activityData.date]: {
              [activityData.feeName]: activityData.quantity
            }
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    selectedActivities.value = {}
  }, [])

  return {
    onSelectActivity
  }
}
