import { useReducer, useCallback, useMemo, useEffect } from 'react'

import { identity } from '@bonitour/common-functions'

import { customCommissionReducer } from './customCommissionReducer'

export const useCustomCommission = ({
  onUpdate = identity,
  initialData = [],
  baseCommission = 20,
  isNetFeeDefault = false,
  isDisabledForm = false,
  submitCount = 0
}) => {
  const [customCommissions, dispatchCustomComissons] = useReducer(
    customCommissionReducer({
      baseCommission,
      isNetFeeDefault,
      onUpdate
    }),
    initialData
  )

  const handleAddCustomCommission = useCallback(() => {
    dispatchCustomComissons({ type: 'add' })
  }, [])

  const handleRemoveCustomCommission = useCallback(index => {
    dispatchCustomComissons({ type: 'remove', index })
  }, [])

  const handleUpdateServiceIdCustomCommission = useCallback((index, value) => {
    dispatchCustomComissons({
      type: 'update',
      index,
      field: 'service',
      value
    })
  }, [])

  const handleUpdateCommissionCustomCommission = useCallback((index, value) => {
    dispatchCustomComissons({
      type: 'update',
      index,
      field: 'commission',
      value
    })
  }, [])

  const handleToggleNetFeeEnabledcustomCommission = useCallback((index) => {
    dispatchCustomComissons({
      type: 'update',
      index,
      field: 'netFeeEnabled'
    })
  }, [])

  const selectedServicesIds = useMemo(() => customCommissions.map(({ service }) => service), [customCommissions])

  const processedCustomCommissions = useMemo(() => (isDisabledForm ? initialData : customCommissions)
    .map((customCommission) => ({
      ...customCommission,
      errors: {
        service: !customCommission.isAddition && !customCommission.service,
        commission: !customCommission.isAddition && (Number(customCommission.commission) < 0 || (!customCommission.commission && customCommission.commission !== 0))
      }
    })), [customCommissions, initialData, isDisabledForm])

  const hasCommissionError = useMemo(() => processedCustomCommissions.some(({ errors }) => errors?.commission), [processedCustomCommissions])

  const hasSomeCommissionAsAddition = useMemo(() => customCommissions.some(({ isAddition }) => isAddition), [customCommissions])

  const removeIsAdditionFromCustomCommissions = useCallback(() => {
    if (!hasSomeCommissionAsAddition) return

    customCommissions.forEach((customCommission, index) => {
      if (customCommission.isAddition) {
        dispatchCustomComissons({
          type: 'update',
          index,
          field: 'isAddition'
        })
      }
    })
  }, [customCommissions, hasSomeCommissionAsAddition])

  useEffect(() => {
    removeIsAdditionFromCustomCommissions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount])

  return {
    handleAddCustomCommission,
    handleRemoveCustomCommission,
    handleToggleNetFeeEnabledcustomCommission,
    handleUpdateCommissionCustomCommission,
    handleUpdateServiceIdCustomCommission,
    customCommissions: processedCustomCommissions,
    hasCommissionError,
    selectedServicesIds
  }
}
