import { useCallback, useEffect, useMemo, useState } from 'react'

import { object } from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const identitySchema = object()

export const usePartnershipGroupForm = (schema = identitySchema, initialData = {}) => {
  const [hasAlreadyLoadedInitialData, setHasAlreadyLoadedInitialData] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, submitCount },
    clearErrors,
    control,
    trigger
  } = useForm({
    resolver: yupResolver(schema, {
      stripUnknown: true,
      abortEarly: false
    }),
    reValidateMode: 'onChange'
  })

  const loadInitialData = useCallback((initialData) => {
    for (const key in initialData) {
      setValue(key, initialData[key])
    }
  }, [setValue])

  const onChange = useCallback((fieldName) => (value) => {
    setValue(fieldName, value)
  }, [setValue])

  const tradeAgreement = watch('tradeAgreement')
  const customCommissions = watch('customCommissions')
  const invoicePolicy = watch('invoicePolicy')
  const comercialPartners = watch('comercialPartners')

  const updatedFormState = useMemo(() => ({
    errors,
    submitCount,
    data: {
      tradeAgreement,
      customCommissions,
      invoicePolicy,
      comercialPartners
    }
  }), [errors, submitCount, tradeAgreement, customCommissions, invoicePolicy, comercialPartners])

  const formUtils = useMemo(() => ({
    register,
    onChange,
    watch,
    setValue,
    clearErrors,
    control,
    trigger
  }), [register, onChange, watch, setValue, clearErrors, control, trigger])

  useEffect(() => {
    const isInitialDataEmpty = Object.keys(initialData).length === 0
    if (hasAlreadyLoadedInitialData || isInitialDataEmpty) return
    loadInitialData(initialData)
    setHasAlreadyLoadedInitialData(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData])

  return {
    formUtils,
    handleSubmit,
    updatedFormState
  }
}
