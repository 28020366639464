import { css } from '@emotion/core'
import { colors, flexColumn } from '@bonitour/components'
import { marginRight } from 'assets/styles/global'

export const skeletonCss = css`
  pointer-events: none;
  opacity: 0.8;
  width: 5rem;
  height: 1rem;
  border-radius: var(--round-element-radius);

  position: relative;

  &, * {
    cursor: default;
  }

  > div:last-of-type {
    display: flex;
  }

  background-color: ${colors.gray7};
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    transform: translateX(-100%);
    animation: moveBg 2s infinite;
    content: '';
    background-image: linear-gradient(
      90deg,
      rgba(240, 240, 240, 0) 0,
      rgba(240, 240, 240, 0.25) 15%,
      rgba(240, 240, 240, 0.75) 80%,
      rgba(240, 240, 240, 0)
    );
  }

  @keyframes moveBg {
    100% {
      transform: translateX(100%);
    }
  }
`

export const container = css`
  position: relative;
  margin-top: 1.15rem;

  &.opacity {
    opacity: 0;
  }

  &.limit-of-day-content {
    position: absolute;
    bottom: -0.50rem;
  }
`

export const customTooltip = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 1.563rem;
  padding: 0.625rem;
  font-weight: 700;
  font-size: 0.875rem;
`

export const fullWidth = css`
  width: 100%;
`

export const ageRange = css`
  font-size: 0.75rem;
  font-weight: normal;
`

export const contentContainer = css`
  display: flex;
  cursor: default;
`

export const contentLabels = css`
  ${flexColumn};
  align-items: flex-start;
  ${marginRight(10)};
`

export const contentStyle = css`
  font-size: 0.75rem;
  padding: 0.188rem 0.625rem;
  border-radius: var(--small-round-element-radius, 0.75rem);
`

export const tooltipContentStyle = css`
  height: auto;
  width: 8.75rem;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: pre-line;
  line-height: 1.15rem;
`
