/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { memo, useCallback, useContext, useMemo } from 'react'

import { PaginatedSellersContext } from 'app/Sellers/SellersContext'

import { PartnershipSearchComponent } from './PartnershipSearch'
import { Checkbox, AvatarCard, PaginationContainer, flex } from '@bonitour/components'

import { clickable, skeletonCardEffect } from 'assets/styles/global'
import { partnetCard, partnerCheckbox, paginationContainer, loadingListContainer, notSelectable } from './PartnershipForm.style'
import { flexEnd } from 'domains/PartnershipAccount/Edit/PartnershipTransactions.styles'

const LoadingList = memo(
  ({ elementsCount }) => {
    return (
      <ul css={loadingListContainer}>
        {Array.from({ length: elementsCount }, (_, i) => i).map((key) => (
          <li key={key}/>
        ))}
      </ul>
    )
  },
  (prevProps, nextProps) => prevProps.elementsCount === nextProps.elementsCount
)

export const PartnershipForm = ({
  formUtils = {},
  partnershipList = [],
  tradeAgreementName = '',
  isDisabled = false
}) => {
  const { onChange } = formUtils

  const emitChangeEvent = useCallback((value) => onChange('comercialPartners')(value), [onChange])

  const havePartnership = useCallback((partnerId) => partnershipList.includes(partnerId), [partnershipList])

  const addPartner = useCallback((partnerId) => emitChangeEvent([...partnershipList, partnerId]), [emitChangeEvent, partnershipList])

  const removePartner = useCallback((partnerId) => {
    const filteredPartners = partnershipList.filter((item) => item !== partnerId)
    emitChangeEvent(filteredPartners)
  }, [emitChangeEvent, partnershipList])

  const handlePartner = useCallback((partnerId) => () => {
    if (isDisabled) return
    if (havePartnership(partnerId)) {
      removePartner(partnerId)
    } else {
      addPartner(partnerId)
    }
  }, [addPartner, removePartner, havePartnership, isDisabled])

  const isNoCommissionGroup = useMemo(() => !(tradeAgreementName === 'no_commission_group' || tradeAgreementName === 'Meu grupo'), [tradeAgreementName])

  const { sellersInfo, loadingSellers, pagination, handlePagination } = useContext(PaginatedSellersContext)

  const paginationMiddleware = useCallback(
    (...props) => {
      if ((pagination.currentPage === props[0] && pagination.pageLimit === props[1]) || loadingSellers) {
        return
      }
      handlePagination(...props)
    },
    [handlePagination, pagination.currentPage, pagination.pageLimit, loadingSellers]
  )

  const totalEntries = useMemo(() => sellersInfo.total, [sellersInfo.total])
  const totalPages = useMemo(() => sellersInfo.totalPages, [sellersInfo.totalPages])
  const perPage = useMemo(() => pagination.pageLimit, [pagination.pageLimit])

  return (
    <>
      <div css={[flex, flexEnd]}>
        <PartnershipSearchComponent isDisabled={isDisabled} />
      </div>
      <PaginationContainer
        total={totalEntries}
        pagesQuantity={totalPages}
        onPagination={paginationMiddleware}
        childrenCustomCss={[paginationContainer]}
      >
        {loadingSellers
          ? (
            <LoadingList elementsCount={perPage} />
          )
          : (
            sellersInfo.sellers.map(({ id, name, image }) => (
              <AvatarCard
                css={[partnetCard, isNoCommissionGroup && clickable, notSelectable, isDisabled && skeletonCardEffect]}
                key={id}
                image={image}
                title={name}
                onClick={isNoCommissionGroup && handlePartner(id)}
              >
                <Checkbox
                  css={partnerCheckbox}
                  checked={havePartnership(id)}
                  onChange={isNoCommissionGroup && handlePartner(id)}
                  disabled={!isNoCommissionGroup || isDisabled}
                />
              </AvatarCard>
            ))
          )}
      </PaginationContainer>
    </>
  )
}
