/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { TariffTooltipContent } from './TariffTooltipContent.js'
import { Manager, Reference } from 'react-popper'
import { Tooltip } from '@bonitour/components'

export const TariffTooltip = ({
  isVisible = false,
  activitiesPricingData = [],
  children
}) => {
  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div ref={ref}>
            {children}
          </div>
        )}
      </Reference>

      {isVisible
        ? <Tooltip position='bottom'>
          <TariffTooltipContent activitiesPricingData={activitiesPricingData} isVisibleTooltip={isVisible} />
        </Tooltip>
        : null}
    </Manager>
  )
}
