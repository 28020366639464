import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const activitySelector = css`
  user-select: none;
  display: inline-block;
  cursor: pointer;
`

export const companyTitle = css`
  font-size: 12px;
  color: ${colors.primary};
`

export const activityTitle = css`
  font-size: 24px;
  color: ${colors.primary};
  font-weight: bold;
  display: flex;
  margin-right: 0.75rem;
`

export const activityTitleIcon = css`
  font-size: 16px;
  position: relative;
`

export const activityPopoverWrapper = css`
    position: relative;
    margin-left: 5px;
`

export const popoverStyle = css`
  margin: 0;
  margin-top: -10px;
  margin-right: 30px;
  text-align: left;
  max-width: 230px;
  padding: 15px;
  box-sizing: border-box;
  transform: translate3d(-95px, 54px, 0px) !important;
`

export const selectorStyle = css`
  margin: 0;
  max-height: 400px;
`

export const hidePopover = css`
  display: none;
`

export const dialogContainer = css`
  max-width: 800px;
`

export const skeletonLabel = css`
  pointer-events: none;
  opacity: 0.8;
  width: 10rem;
  height: 1.50rem;
  border-radius: var(--round-element-radius);

  position: relative;

  &, * {
    cursor: default;
  }

  > div:last-of-type {
    display: flex;
  }

  background-color: ${colors.gray7};
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    transform: translateX(-100%);
    animation: moveBg 2s infinite;
    content: '';
    background-image: linear-gradient(
      90deg,
      rgba(240, 240, 240, 0) 0,
      rgba(240, 240, 240, 0.25) 15%,
      rgba(240, 240, 240, 0.75) 80%,
      rgba(240, 240, 240, 0)
    );
  }

  @keyframes moveBg {
    100% {
      transform: translateX(100%);
    }
  }
`

export const unclickable = css`
  pointer-events: none;
  cursor: default;
`
