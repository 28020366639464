/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { ExperienceGuideItem, MapGuideItem, RestrictionGuideItem } from './components'
import {
  BanIcon,
  Content,
  UserIcon,
  CompassIcon,
  colors,
  flexCenteredSpaceBetweenRow,
  flexHorizontallyCentered,
  flexRowCentered
} from '@bonitour/components'

import { useActivity } from 'contexts/Activity'

import { footer, footerContent } from './bookingMapGuide.styles'

import { EXPERIENCE_VACANCY_TYPE_OPACITY } from 'constants/bookingMap'

export const BookingMapGuide = ({
  isCombinedExperience = false,
  activitiesColors = []
}) => {
  const { activity } = useActivity()
  const { minAge = 0, duration = '' } = activity

  return (
    <div css={[footer, flexRowCentered]}>
      <Content css={[footerContent, flexCenteredSpaceBetweenRow]}>
        <div css={flexHorizontallyCentered}>
          {!isCombinedExperience && (
            <>
              {(Boolean(minAge) || Boolean(duration)) && <span>Restrições técnicas:</span>}
              {Boolean(minAge) && <RestrictionGuideItem icon={UserIcon} tooltip={`Idade mínima de ${minAge} anos`} />}
              {Boolean(duration) && <RestrictionGuideItem icon={CompassIcon} tooltip={`Aproximadamente ${duration} hs`} />}
            </>
          )}
        </div>
        <div css={flexHorizontallyCentered}>
          {isCombinedExperience
            ? (
              <>
                <ExperienceGuideItem
                  colors={activitiesColors}
                  opacity={EXPERIENCE_VACANCY_TYPE_OPACITY.reservationsTotal}>
                  Vagas reservadas
                </ExperienceGuideItem>
                <ExperienceGuideItem
                  colors={activitiesColors}
                  opacity={EXPERIENCE_VACANCY_TYPE_OPACITY.totalAvailable}>
                    Vagas disponíveis
                </ExperienceGuideItem>
                <ExperienceGuideItem
                  colors={activitiesColors}
                  opacity={EXPERIENCE_VACANCY_TYPE_OPACITY.bookingNow}
                  isBorderColorful={true}>
                    Reservando agora
                </ExperienceGuideItem>
                <MapGuideItem icon={BanIcon} canRemoveMarginLeft={true}>
                  Vagas indisponíveis
                </MapGuideItem>
              </>
            )
            : (
              <>
                <MapGuideItem color={colors.primaryVariant}>
                  Vagas reservadas
                </MapGuideItem>
                <MapGuideItem color={colors.white}>
                  Vagas disponíveis
                </MapGuideItem>
                <MapGuideItem icon={BanIcon}>
                  Vagas indisponíveis
                </MapGuideItem>
              </>
            )}
        </div>
      </Content>
    </div>
  )
}
