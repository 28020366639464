import { BREAK_POINTS, colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const footer = css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2.5rem;
  background: ${colors.white};
  color: ${colors.gray5};
  box-shadow: 0px -1px 0.375rem #00000029;
  z-index: 10;

  @media (max-width: ${BREAK_POINTS.tablet}) {
    display: none;
  }
`

export const footerContent = css`
  padding: 0.625rem 1.25rem;
`
