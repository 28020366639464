/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card } from '@bonitour/components'
import { partnershipSectionContainer } from './PartnershipSectionContainer.styles'

export const PartnershipSectionContainer = ({
  children
}) => {
  return (
    <Card css={partnershipSectionContainer}>
      {children}
    </Card>
  )
}
