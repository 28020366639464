/** @jsxRuntime classic */
/** @jsx jsx */
import { BanIcon, colors, TableBodyCell } from '@bonitour/components'
import { jsx, css } from '@emotion/core'
import { pulse } from 'assets/animations/pulse'
import { memo } from 'react'

const vacancyCard = css`
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 56px;
  margin: 5px;
  border-radius: 7px;
  box-shadow: 0px 1px 5px #00000014;
  margin-left: auto;
  margin-right: auto;
`

const detailedContainer = css`
  padding: 5px;
  vertical-align: top;
  border: 0;
`

const vacancyCardBlocked = css`
  width: 96px;
  background-color: ${colors.gray12};
  box-shadow: initial;
  animation: ${pulse} 0.2s linear;
  box-shadow: inset 0px 0px 0px 1px ${colors.gray8};
  border-radius: 0.35rem;
  &.minified {
    width: 100%;
    margin: 0;
    height: min-content;
    padding: 0.25rem 0 0.25rem;
  }
  &.static-w {
    width: 6.15rem;
  }
`

const vacancyCardValue = css`
  font-size: 15px;
  font-weight: 500;
  color: ${colors.gray4};
`

export const BlockedSummaryCell = memo(({
  canMinify = false,
  isEnabledStaticWidth = false,
  customCss = []
}) => (
  <TableBodyCell css={[detailedContainer, ...customCss]}>
    <div css={[vacancyCard, vacancyCardBlocked]} className={[canMinify ? 'minified' : '', isEnabledStaticWidth ? 'static-w' : ''].join(' ')}>
      <span css={vacancyCardValue}>
        <BanIcon />
      </span>
    </div>
  </TableBodyCell>
))
