/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableHeader, TableHeaderCell, TableHeaderRow } from '@bonitour/components'
import { MonthLabel } from './VacancyTableMonthLabel'
import { VacancyTableDateIndicator } from './VacancyTableDateIndicator'
import { VacancyTableContent } from './VacancyTableContent'

import 'moment/locale/pt-br'
import moment from 'moment'
import { identity } from '@bonitour/common-functions'
import { useMemo } from 'react'

moment.locale('pt-BR')

const month = css`
  padding: 5px;
  width: 122px;
`

const day = css`
  padding: 5px 5px 10px 5px;
  width: 110px;
  text-align: center;
`

export const VacancyTableHeader = ({
  onExceptionalDayEditClick = identity,
  checkCanShowLimitOfDay = identity,
  getLimitOfDay = identity,
  getFeesByDate = identity,
  selectedDate = new Date(),
  isActivityOwner = false,
  dateColumns = [],
  tablePrices = {},
  isCombinedExperience = false,
  isLoadingVacancies = false,
  ...other
}) => {
  const monthLabel = moment(selectedDate).format('MMMM/Y')

  const feesByDateIndex = useMemo(() => dateColumns.map((date) => {
    const currentActivitiesFees = getFeesByDate(date)

    const biggestFee = currentActivitiesFees.length
      ? currentActivitiesFees
        .reduce((max, { biggerFee }) => Math.max(max, biggerFee), -Infinity)
      : 0

    return {
      fees: currentActivitiesFees,
      biggestFee
    }
  }), [dateColumns, getFeesByDate])

  return (
    <TableHeader {...other}>
      <TableHeaderRow>
        <TableHeaderCell css={month}>
          <MonthLabel monthContent={monthLabel} />
        </TableHeaderCell>
        {
          dateColumns.map((date, index) => {
            const limitOfDayOpts = checkCanShowLimitOfDay(date)

            const limitOfDay = getLimitOfDay(date)

            return <TableHeaderCell css={day} key={`date-column-${index}`}>
              <VacancyTableDateIndicator
                date={date}
                onExceptionalDayEditClick={onExceptionalDayEditClick}
                isActivityOwner={isActivityOwner}
                limitOfDayOpts={[...limitOfDayOpts, limitOfDay]}
                canDisableExceptionalDay={isCombinedExperience}
              />

              <VacancyTableContent
                isPriceDetail={true}
                dayPrices={tablePrices[date]}
                isCombinedExperience={isCombinedExperience}
                activitiesFeesData={feesByDateIndex[index]}
                isLoadingVacancies={isLoadingVacancies}
              />
            </TableHeaderCell>
          })
        }
      </TableHeaderRow>
    </TableHeader>
  )
}
