/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { colors, PartnersIcon } from '@bonitour/components'
import { partnershipQuantityRow } from './PartnershipCounter.styles'

export const PartnershipCounter = ({
  selectedQuantity = 0
}) => {
  return (
    <span css={partnershipQuantityRow}>
      <PartnersIcon color={colors.white}/>
      <span>
        {!selectedQuantity
          ? '0'
          : selectedQuantity}
      </span>
    </span>
  )
}
