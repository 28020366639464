/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useCallback, memo, useContext } from 'react'

import { CombinedExperienceSummaryCellGroup } from '../CombinedExperienceSummaryCellGroup'
import { BlockedSummaryCell } from '../../../BodyCell/BlockedSummaryCell'

import { flexColumn, increasedWidth, padding0, w100 } from './combinedExperienceSummary.styles'
import { BookingMapInfoCtx } from 'domains/Booking/Map/contexts/useBookingMapInfo'

const ExperienceSummary = ({
  activitiesData = [],
  dateColumns = [],
  date = ''
}) => {
  const getCanSetStaticWidth = useCallback((currentActivityId) => {
    if (activitiesData.length === 1) return true
    const haveActivityWithSlotsInSameDate = activitiesData.some((activityData) => {
      if (activityData.activityId === currentActivityId) return false
      return Boolean(activityData.slots?.[date])
    })
    return !haveActivityWithSlotsInSameDate
  }, [activitiesData, date])

  const getBlockedSummaryAttrs = useCallback((activityData, isAvailable, activityId) => {
    const canShowBlockedSummary = !activityData || !isAvailable

    const isEnabledStaticSummaryWidth = canShowBlockedSummary && getCanSetStaticWidth(activityId)

    return [canShowBlockedSummary, isEnabledStaticSummaryWidth]
  }, [getCanSetStaticWidth])

  const {
    loadedSelectedActivities
  } = useContext(BookingMapInfoCtx)

  const getSelectedTotals = useCallback((activityId, hour, date) => {
    const activity = loadedSelectedActivities?.[activityId]
    if (!activity) return 0

    const hourData = activity?.[hour]
    if (!hourData) return 0

    const dateData = hourData?.[date]
    if (!dateData) return 0

    return Object.values(dateData).reduce((sum, quantity) => sum + (quantity || 0), 0)
  }, [loadedSelectedActivities])

  return (
    <div css={[flexColumn, increasedWidth]}>
      {activitiesData.map((activityData, activityDataIndex) => {
        const {
          vacancies = {},
          reservations = {},
          activityId = '',
          color = '',
          hour = 'without-hour',
          duration = ''
        } = activityData || {}

        const {
          total: totalAvailable = 0,
          isAvailable
        } = vacancies || {}

        const {
          total: reservationsTotal = 0
        } = reservations || {}

        const isFirstDateOfHour = dateColumns[0] === date

        const activityDataUniqueKey = `${activityId}-${activityDataIndex}-${hour}`

        const [canShowBlockedSummary, isEnabledStaticSummaryWidth] = getBlockedSummaryAttrs(activityData, isAvailable, activityId)

        const selectedTotals = getSelectedTotals(activityId, hour, date)

        return <CombinedExperienceSummaryCellGroup
          key={`experience-summary-${activityDataUniqueKey}`}
          color={color}
          duration={duration}
          isFirstDateOfHour={isFirstDateOfHour}
          isBlockedSummary={canShowBlockedSummary}
          vacancyTotals={{
            totalAvailable,
            selectedTotals,
            reservationsTotal
          }}
        >
          <BlockedSummaryCell
            key={`vacancy-unavailable-${activityDataUniqueKey}`}
            canMinify={true}
            isEnabledStaticWidth={isEnabledStaticSummaryWidth}
            customCss={[padding0, w100]}
          />
        </CombinedExperienceSummaryCellGroup>
      })}
    </div>
  )
}

export const CombinedExperienceSummary = memo(ExperienceSummary)
