import styled from '@emotion/styled'

export const FeeRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${prop => prop.bgColor};
  padding: 0.35rem;
  padding-left: 0.65rem;
  width: 100%;
  &:first-of-type {
    padding-top: 0.75rem;
  }
  &:last-of-type {
    padding-bottom: 0.75rem;
  }
  section {
    margin-left: 0.65rem;
    text-align: start;
    span {
      display: block;
      width: 7rem;
      margin-left: 0;
      font-weight: bolder;
      font-size: 1rem;
    }
    p {
      margin-left: 0;
      font-weight: 600;
      font-size: 0.85rem;
    }
  }
`
