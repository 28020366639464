/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import {
  TableContainer,
  TableBody,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBodyRow,
  TableBodyCell,
  Button,
  TooltipOnHover,
  Checkbox,
  colors,
  CircleThumbnail
} from '@bonitour/components'
import { TicketStatus } from 'containers/Reservation/TicketStatus'
import { identity } from '@bonitour/common-functions'
import { clickable } from 'assets/styles/global'
import { useMemo } from 'react'
import { ticketStatusDictionary } from 'constants/ticketStatus'

const button = css`
  width: 100%;
`

const resetPosition = css`
  align-items: unset;
  justify-content: unset;
`

const shortenFields = css`
  overflow: hidden;
  text-overflow: ellipsis;
`

const bigFields = css`
  ${shortenFields}
  max-width: 125px;
`

const smallFields = css`
  ${shortenFields}
  max-width: 100px;
`

const padding10 = css`
  padding: 20px 10px;
`

const paymentPendingThumbnail = css`
  display: inline-flex;
  margin-right: 0.625rem;
  background-color: ${colors.yellow2};
`

const centralizedTooltip = css`
  [class*='tooltip-on-hover'] {
    right: 0%;
    aside > h6 {
      margin-bottom: 0.50rem;
    }
  }
`

const MyDayTableRow = ({
  activity = {},
  isTicketSelectable = false,
  onValidateClick: emitValidateClick = identity,
  onTicketClick: emitTicketClick = identity,
  onNavigation: emitMyDayClickRow = identity,
  isTicketSelect: emitTicketSelect = identity,
  canValidateTicket: emitTicketsValidation = identity
}) => {
  const {
    serviceId = '',
    time = '',
    ticketCode = '',
    serviceName = '',
    passengerName = '',
    paxType = '',
    state = '',
    commercialPartnerName = '',
    paymentStatus = ''
  } = activity
  const isValidateVisible = state === 'confirmed' || state === 'reserved'

  const handleValidateClick = (event) => {
    event.stopPropagation()
    emitValidateClick({ code: ticketCode })
  }

  const handleCheckBoxClick = (event) => {
    event.stopPropagation()
    emitTicketClick(activity)
  }

  const hasPaymentPending = useMemo(() => paymentStatus === ticketStatusDictionary.partially_paid.value, [paymentStatus])

  const ticketCodeHoverText = useMemo(() => {
    if (hasPaymentPending) {
      return (
        <aside>
          <h6>{ticketCode}</h6>
          <span>
          Há pagamentos pendentes para esse ingresso
          </span>
        </aside>
      )
    }

    return ticketCode
  }, [hasPaymentPending, ticketCode])

  return (
    <TableBodyRow css={clickable} onClick={emitMyDayClickRow}>
      <TableBodyCell css={padding10}>
        {isTicketSelectable(state) && emitTicketsValidation(state, serviceId) && (
          <Checkbox checked={emitTicketSelect(ticketCode)} onClick={(event) => handleCheckBoxClick(event)} />
        )}
      </TableBodyCell>
      <TableBodyCell>{time}</TableBodyCell>
      <TruncateCell truncateStyle={smallFields} text={ticketCodeHoverText} customTooltipCss={[centralizedTooltip]}>
        {hasPaymentPending
          ? (
            <CircleThumbnail size={10} css={paymentPendingThumbnail}/>
          )
          : null}
        {ticketCode}
      </TruncateCell>
      <TruncateCell truncateStyle={bigFields} text={commercialPartnerName} />
      <TruncateCell truncateStyle={bigFields} text={serviceName} />
      <TruncateCell truncateStyle={bigFields} text={passengerName} />
      <TableBodyCell>{paxType}</TableBodyCell>
      <TableBodyCell>{TicketStatus(state)}</TableBodyCell>
      <TableBodyCell fixed>
        {isValidateVisible && emitTicketsValidation(state, serviceId) && (
          <Button css={button} onClick={(event) => handleValidateClick(event)}>
            Validar
          </Button>
        )}
      </TableBodyCell>
    </TableBodyRow>
  )
}

export const MyDayTable = ({
  activities = [],
  isTicketInFuture = false,
  loading = 'false',
  isSelectedAll = false,
  onNavigation: emitNavigationEvent = identity,
  isTicketSelectable = identity,
  onValidateClick = identity,
  onSelectedAllClick: emitSelectedAllClick = identity,
  onTicketClick = identity,
  isTicketSelect = identity,
  canValidateTicket = identity
}) => {
  return (
    <TableContainer>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell css={padding10}>
            <Checkbox checked={isSelectedAll} onChange={emitSelectedAllClick} />
          </TableHeaderCell>
          <TableHeaderCell>Horário</TableHeaderCell>
          <TableHeaderCell>
            Cód. <br />
            ingresso
          </TableHeaderCell>
          <TableHeaderCell>
            Canal <br />
            de vendas
          </TableHeaderCell>
          <TableHeaderCell>Experiência</TableHeaderCell>
          <TableHeaderCell>Viajante</TableHeaderCell>
          <TableHeaderCell>Tipo</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell fixed>Ação</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody loading={loading}>
        {activities.map((activity, index) => {
          return (
            activity.status !== 'canceled' && (
              <MyDayTableRow
                key={index}
                activity={activity}
                onNavigation={emitNavigationEvent(activity.reservationId)}
                onValidateClick={onValidateClick}
                isTicketInFuture={isTicketInFuture}
                onTicketClick={onTicketClick}
                isTicketSelectable={isTicketSelectable}
                isTicketSelect={isTicketSelect}
                canValidateTicket={canValidateTicket}
              />
            )
          )
        })}
      </TableBody>
    </TableContainer>
  )
}

const TruncateCell = ({ text, truncateStyle, children, customTooltipCss = [] }) => (
  <TableBodyCell>
    <TooltipOnHover css={resetPosition} text={text} customCss={customTooltipCss}>
      <div css={truncateStyle}>{children || text}</div>
    </TooltipOnHover>
  </TableBodyCell>
)
