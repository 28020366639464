import { css } from '@emotion/core'

export const flexColumn = css`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const increasedWidth = css`
  width: max-content;
`

export const w100 = css`
  width: 100%;
`

export const padding0 = css`
  padding: 0;
`
