/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import { useCallback, useMemo, useState } from 'react'
import { ActivityRow } from './components/ActivityRow'

export const tooltipContentContainerCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.15rem;
`

export const TariffTooltipContent = ({
  activitiesPricingData = [],
  isVisibleTooltip = false
}) => {
  const [currentVisibleActivityPriceDetails, setCurrentVisibleActivityPriceDetails] = useState(null)

  const toggleCurrentVisibleActivityPriceDetails = useCallback((activityId) => () => {
    if (!isVisibleTooltip) return
    if (activityId === currentVisibleActivityPriceDetails) {
      return setCurrentVisibleActivityPriceDetails(null)
    }
    setCurrentVisibleActivityPriceDetails(activityId)
  }, [currentVisibleActivityPriceDetails, isVisibleTooltip])

  const orderedActivitiesByBiggestFee = useMemo(() => {
    return activitiesPricingData
      .sort((a, b) => a.biggerFee - b.biggerFee)
      .reverse()
  }, [activitiesPricingData])

  return (
    <div css={tooltipContentContainerCss}>
      {orderedActivitiesByBiggestFee.map((activityData, index) => (
        <ActivityRow
          id={activityData.id}
          name={activityData.name}
          color={activityData.color}
          fees={activityData.fees}
          biggestFeePricing={activityData.biggerFee}
          key={`tooltip-on-hover-#${index}-activity-${activityData.id}`}
          isVisiblePriceDetailsTooltip={currentVisibleActivityPriceDetails === activityData.id && isVisibleTooltip}
          onTogglePriceDetailsTooltip={toggleCurrentVisibleActivityPriceDetails(activityData.id)}
        />
      ))}
    </div>
  )
}
