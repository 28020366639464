/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useCallback, useMemo } from 'react'

import { useActivity } from 'contexts/Activity'
import { identity } from '@bonitour/common-functions'
import { useFeatureFlag } from 'contexts/Feature'
import { useCustomCommission } from './hooks'

import { PartnershipGroupError } from '../../components'

import { H3, InputWithSuffix, Select, ToggleInputGroup, TooltipOnHover, TrashIcon } from '@bonitour/components'
import { AddButton } from 'components/AddButton'

import {
  customCommissionContainer,
  customCommissionItem,
  customCommissionsList,
  hiddenTooltip,
  marginBottom10,
  marginTop20,
  tooltip,
  width100prc
} from './CustomCommissions.styles'

export const CustomCommissions = ({
  isNetFeeDefault = false,
  baseCommission = 20,
  submitCount = 0,
  onChange = identity,
  initialData = [],
  isDisabled = false,
  isGroupEditable
}) => {
  const [isNetValueEnabled] = useFeatureFlag('orb-net-value')

  const {
    customCommissions,
    hasCommissionError,
    handleAddCustomCommission,
    handleRemoveCustomCommission,
    handleToggleNetFeeEnabledCustomCommission,
    handleUpdateCommissionCustomCommission,
    handleUpdateServiceIdCustomCommission,
    selectedServicesIds
  } = useCustomCommission({
    initialData,
    baseCommission,
    isNetFeeDefault,
    onUpdate: onChange,
    isDisabledForm: isDisabled,
    submitCount
  })

  const { companyActivities, partnerActivities } = useActivity()

  const companyServicesOptions = useMemo(() => {
    return companyActivities.concat(partnerActivities).map(activity => ({
      value: activity.id,
      label: activity.name
    }))
  }, [companyActivities, partnerActivities])

  const [activitiesOptions, filteredActivitiesOptions] = useMemo(() => {
    const preSelected = initialData
      .map(({ service: serviceId, titleName }) => ({
        value: serviceId,
        label: titleName
      }))

    const allOpts = [...companyServicesOptions, ...preSelected]

    return [
      allOpts,
      allOpts.filter(({ value }) => !selectedServicesIds.includes(value))
    ]
  }, [companyServicesOptions, initialData, selectedServicesIds])

  const getServiceLabel = useCallback((serviceId) => activitiesOptions.find(({ value: serviceValue }) => serviceValue === serviceId)?.label, [activitiesOptions])

  const getCanShowTooltip = useCallback((serviceId) => {
    const serviceLabel = getServiceLabel(serviceId)
    return serviceLabel && serviceLabel.length > 36
  }, [getServiceLabel])

  return (
    <section css={customCommissionContainer}>
      <H3 css={[marginTop20, marginBottom10]}>
        Comissionamento personalizado
      </H3>

      <ul css={customCommissionsList}>
        {customCommissions.map((customCommission, index) => (
          <li key={index} css={customCommissionItem}>
            <div className='item__input'>
              <span className='input__label'>Serviço</span>
              <TooltipOnHover text={getServiceLabel(customCommission.service)} customCss={[getCanShowTooltip(customCommission.service) ? tooltip : hiddenTooltip]}>
                <Select
                  options={customCommission.service ? activitiesOptions : filteredActivitiesOptions}
                  value={customCommission.service}
                  customCss={[width100prc]}
                  onChange={value => handleUpdateServiceIdCustomCommission(index, value)}
                  error={customCommission?.errors?.service}
                  disabled={isDisabled || !isGroupEditable}
                  className='select__service'
                />
              </TooltipOnHover>
            </div>

            <div className='item__input'>
              <span className='input__label'>Porcentagem</span>
              <InputWithSuffix
                min='0'
                type='number'
                value={customCommission.commission}
                onChange={(value) => handleUpdateCommissionCustomCommission(index, value)}
                onBlur={(value) => handleUpdateCommissionCustomCommission(index, value)}
                error={customCommission?.errors?.commission}
                disabled={isDisabled || !isGroupEditable}
              >%
              </InputWithSuffix>
            </div>

            {isNetValueEnabled
              ? (
                <div className='item__input'>
                  <span className='input__label'>Tarifa Net</span>
                  <ToggleInputGroup
                    onChange={() => handleToggleNetFeeEnabledCustomCommission(index)}
                    checked={customCommission.netFeeEnabled}
                    disabled={isDisabled}
                  />
                </div>
              )
              : <span>&nbsp;</span>}

            <div className='item__input delete__container'>
              <button
                type='button'
                onClick={() => handleRemoveCustomCommission(index)}
                disabled={isDisabled || !isGroupEditable}
              >
                <TrashIcon/>
              </button>
            </div>
          </li>
        ))}
      </ul>

      <PartnershipGroupError
        isVisible={submitCount > 0 && hasCommissionError}
        error={hasCommissionError ? 'Confira a lista de comissões personalizadas' : ''}
        isTooltip={false}
        canShowOnBottom={true}
        isRawError={true}
      />

      <AddButton
        onClick={handleAddCustomCommission}
        disabled={isDisabled || (!isNetValueEnabled && !isGroupEditable)}
      >
            Nova Comissão Personalizada
      </AddButton>

    </section>
  )
}
