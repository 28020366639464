import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const partnershipQuantityRow = css`
  border-radius: var(--round-element-radius);
  background-color: ${colors.white};
  color: ${colors.gray2};
  position: absolute;
  width: 3.25rem;
  height: 1.5rem;
  right: 0.5rem;
  top: 0.5rem;
  margin-left: 1rem;
  display: inline-flex;
  gap: 0.25rem;
  place-items: center;
  i, span {
    text-align: center;
    width: 50%;
  }
`
