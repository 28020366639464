/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { marginTop30, partnershipSectionContainer } from './PartnershipSection.styles'

import { hidden } from 'assets/styles/global'

export const PartnershipSection = ({
  canHide = false,
  isFirstSection = false,
  children
}) => {
  return (
    <div css={[partnershipSectionContainer, canHide && hidden, isFirstSection && marginTop30]} className='partnership-section'>
      {children}
    </div>
  )
}
