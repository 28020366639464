/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useMemo, useRef } from 'react'
import { colors, BaseTooltip, flexRowCentered, flexCenteredSpaceBetweenRow, ConditionalView } from '@bonitour/components'
import { TariffTooltip } from 'domains/CombinedExperience/components'
import { useHover } from '@bonitour/app-functions'
import { formatMoney } from '@bonitour/common-functions'
import { ageRange, container, contentContainer, contentLabels, contentStyle, customTooltip, fullWidth, skeletonCss, tooltipContentStyle } from './VacancyTableContent.styles'
import { marginTop } from 'assets/styles/global'

const marginTop10 = marginTop(10)

const VacancyContentTooltip = ({
  price = null,
  limitOfDay = 0,
  styleOpts = {
    bgColor: colors.white,
    textColor: colors.gray6
  },
  children = undefined,
  showChildrenAsContent = false,
  ...others
}) => {
  const { bgColor, textColor } = styleOpts

  const canDisplayChildren = useMemo(() => !showChildrenAsContent && children !== undefined, [showChildrenAsContent, children])
  const canDisplayPrice = useMemo(() => !showChildrenAsContent && price !== null, [showChildrenAsContent, price])

  return <div css={contentContainer} {...others}>
    {canDisplayChildren ? children : null}
    <div css={contentStyle} style={{
      backgroundColor: bgColor,
      color: textColor
    }}>
      {canDisplayPrice ? formatMoney(price) : (showChildrenAsContent ? children : limitOfDay)}
    </div>
  </div>
}

const VacancyContentTooltipDetails = ({
  tooltipText = '',
  tooltipDetails = null,
  isLimitOfDayLabel = false
}) => {
  const { minAge, maxAge } = tooltipDetails || {}
  const canShowDetails = useMemo(() => !isLimitOfDayLabel && tooltipDetails !== null, [isLimitOfDayLabel, tooltipDetails])

  return <div css={isLimitOfDayLabel ? tooltipContentStyle : contentLabels}>
    {isLimitOfDayLabel ? tooltipText : <span>{tooltipText}</span>}
    {canShowDetails && (
      <span css={ageRange}>
        {`${minAge} a ${maxAge} anos`}
      </span>
    )}
  </div>
}

export const VacancyTableContent = ({
  dayPrices = [],
  activitiesFeesData = {},
  limitOfDay = 0,
  occupiedVacanciesTotals = 0,
  isCombinedExperience = false,
  isLoadingVacancies = false,
  isPriceDetail = false,
  isNearLimitOfDay = false,
  isSafeLimitOfDay = false
}) => {
  const [ref, isVisible] = useHover(200)

  const value = useMemo(() => {
    if (isPriceDetail) {
      return dayPrices?.[0]?.pricing
    }
    return `${occupiedVacanciesTotals}/${limitOfDay}`
  }, [isPriceDetail, occupiedVacanciesTotals, limitOfDay, dayPrices])

  const containerClasses = useMemo(() => [isPriceDetail && !value && !isCombinedExperience && 'opacity', !isPriceDetail && 'limit-of-day-content'].join(' '), [isCombinedExperience, isPriceDetail, value])

  const {
    contentTextColor,
    contentBgColor,
    tooltipText
  } = useMemo(() => ({
    contentTextColor: colors.white,
    contentBgColor: isNearLimitOfDay ? colors.yellow1 : isSafeLimitOfDay ? colors.gray5 : colors.red3,
    tooltipText: isNearLimitOfDay ? 'Próximo ao limite diário de vagas' : isSafeLimitOfDay ? 'Limite diário de vagas' : 'O limite diário de vagas foi atingido'
  }), [isNearLimitOfDay, isSafeLimitOfDay])

  const isLoadedRef = useRef(false)

  if (isLoadingVacancies && !isLoadedRef.current) {
    isLoadedRef.current = true
  }

  if (isCombinedExperience) {
    const hasFees = Boolean(activitiesFeesData.fees?.length)

    return (
      <div className={containerClasses} css={[container, flexRowCentered]} ref={ref}>
        {(isLoadingVacancies || (!activitiesFeesData.biggestFee && !isLoadedRef.current))
          ? <div css={skeletonCss} />
          : (
            <TariffTooltip
              activitiesPricingData={activitiesFeesData.fees}
              isVisible={!hasFees ? false : isVisible}
            >
              <VacancyContentTooltip
                showChildrenAsContent={true}
                styleOpts={hasFees
                  ? undefined
                  : {
                    bgColor: colors.white,
                    textColor: colors.gray8
                  }}
              >
                {formatMoney(activitiesFeesData.biggestFee)}
              </VacancyContentTooltip>
            </TariffTooltip>
          )}
      </div>
    )
  }

  return (
    <div className={containerClasses} css={[container, flexRowCentered]} ref={ref}>
      <ConditionalView isVisible={isVisible}>
        <BaseTooltip position='bottom' css={customTooltip}>
          {isPriceDetail
            ? (
              dayPrices.map(({ name, pricing, minAge, maxAge }, index) =>
                <VacancyContentTooltip
                  key={`day-price-${index}`}
                  css={[flexCenteredSpaceBetweenRow, marginTop10, fullWidth]}
                  price={pricing}
                  styleOpts={{
                    textColor: colors.gray3,
                    bgColor: colors.white
                  }}>
                  <VacancyContentTooltipDetails
                    tooltipText={name}
                    tooltipDetails={{ minAge, maxAge }}
                  />
                </VacancyContentTooltip>
              )
            )
            : (
              <VacancyContentTooltipDetails
                isLimitOfDayLabel={true}
                tooltipText={tooltipText}
              />
            )}
        </BaseTooltip>
      </ConditionalView>
      <VacancyContentTooltip
        {...(
          isPriceDetail
            ? { price: value }
            : { limitOfDay: value, styleOpts: { textColor: contentTextColor, bgColor: contentBgColor } }
        )}
      />
    </div>
  )
}
