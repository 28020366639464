import { identity } from '@bonitour/common-functions'

export const baseCustomCommission = {
  service: '',
  commission: 0,
  netFeeEnabled: false
}

export const customCommissionReducer = ({
  baseCommission = 0,
  isNetFeeDefault = false,
  onUpdate = identity
}) => (state, action) => {
  let newState = state

  const getSafeCommission = (val) => {
    const numericVal = Number(val)

    if (!val) return 0
    if (val < 0) return 0
    if (numericVal > 100) return 100

    return numericVal
  }

  const safeUpdate = (field, currVal, newVal) => {
    if (field === 'service') {
      const someItemWithSameService = newState && newState.some((customCommission) => customCommission.service === newVal)

      if (someItemWithSameService) {
        return currVal
      }
    }

    if (field === 'commission') {
      return getSafeCommission(newVal)
    }

    if (field === 'netFeeEnabled') {
      return !currVal
    }

    if (field === 'isAddition') {
      return !currVal
    }

    return newVal
  }

  switch (action.type) {
  case 'add':
    newState = [
      ...state,
      {
        ...baseCustomCommission,
        netFeeEnabled: isNetFeeDefault,
        commission: baseCommission,
        isAddition: true
      }
    ]
    break
  case 'remove':
    newState = state.filter((_, index) => index !== action.index)
    break
  case 'update':
    newState = state.map((customCommission, index) => {
      if (index === action.index) {
        return {
          ...customCommission,
          [action.field]: safeUpdate(
            action.field,
            customCommission[action.field],
            action.value
          )
        }
      }
      return customCommission
    })
    break
  default:
    break
  }

  onUpdate(newState)
  return newState
}
