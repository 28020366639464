/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useMemo } from 'react'

import { useActivity } from 'contexts/Activity.js'

import { AngleDownIcon } from '@bonitour/components'
import { ExperienceSelectorModal } from 'containers/Reservations/ReservationList/ExperienceSelectorModal'

import { activityTitle, companyTitle, activitySelector, activityTitleIcon, activityPopoverWrapper, skeletonLabel, unclickable } from './Selector.style'

import { OFFLINE_EXPERIENCE_TYPE } from 'constants/activityTypes'

export const ActivitySelector = ({ subdomain, disableOffline = false, ...other }) => {
  const { activity: { name: activityName, companyName } } = useActivity()

  const disabledTypes = useMemo(() => {
    if (subdomain === 'bookings' || disableOffline) {
      return [OFFLINE_EXPERIENCE_TYPE]
    }
    return []
  }, [disableOffline, subdomain])

  const canShowSkeleton = useMemo(() => !activityName, [activityName])

  return (
    <>
      <ExperienceSelectorModal subdomain={subdomain} disabledTypes={disabledTypes} isDisabledOnClick={canShowSkeleton}>
        <div css={[activitySelector, canShowSkeleton && unclickable]} {...other}>
          <span css={companyTitle}>
            {!canShowSkeleton ? companyName : ''}
          </span>
          <span css={activityTitle}>
            {canShowSkeleton
              ? <span css={skeletonLabel}/>
              : <>
                {activityName}

                <div css={activityPopoverWrapper}>
                  <AngleDownIcon css={activityTitleIcon} />
                </div>
              </>}
          </span>
        </div>
      </ExperienceSelectorModal>
    </>
  )
}
