/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useCallback } from 'react'

import { PaginatedSellersProvider } from 'app/Sellers/SellersContext'

import { LoadingAnimation } from '@bonitour/components'

import { PartnershipSection, PartnershipSectionContainer, PartnershipSectionHeader } from '../components'

import { PartnershipForm } from 'containers/PartnershipGroup/Partnership/PartnershipForm'
import { TradeAgreement } from 'containers/PartnershipGroup/TradeAgreement/TradeAgreement'
import { InvoicePolicy } from 'containers/PartnershipGroup/InvoicePolicy/InvoicePolicy'

import { identity } from '@bonitour/common-functions'

import { sectionOrder } from './PartnershipGroupForm.styles'
import { hidden, loadingContainerOverlay, positionRelative } from 'assets/styles/global'

export const PartnershipGroupForm = ({
  formState = { errors: {}, data: {} },
  formUtils = {
    register: identity,
    onChange: identity,
    watch: identity,
    setValue: identity,
    clearErrors: identity,
    control: {},
    trigger: identity
  },
  isGroupEditable = false,
  isLoading = false,
  canMinifyFormLayout = false,
  canDisableAllForm = false,
  allowedSteps = [1, 2, 3]
}) => {
  const {
    errors,
    data: {
      tradeAgreement,
      customCommissions,
      invoicePolicy,
      comercialPartners
    }
  } = formState

  const canHideStep = useCallback((step) => !allowedSteps.includes(step), [allowedSteps])

  return (
    <div css={[sectionOrder, positionRelative]}>
      <div css={[isLoading ? loadingContainerOverlay : hidden]}>
        <LoadingAnimation/>
      </div>
      <PartnershipSection canHide={canHideStep(1)} isFirstSection>
        <PartnershipSectionHeader
          step={1}
          title='Acordo comercial'
          topics={['Nome da categoria', 'Comissionamento']}
        />
        <PartnershipSectionContainer>
          <TradeAgreement
            tradeAgreement={tradeAgreement}
            errors={errors?.tradeAgreement || {}}
            isGroupEditable={isGroupEditable}
            savedCustomCommissions={customCommissions}
            isLoading={isLoading}
            formUtils={formUtils}
            submitCount={formState.submitCount}
            canDisableAllForm={canDisableAllForm}
          />
        </PartnershipSectionContainer>
      </PartnershipSection>

      <PartnershipSection canHide={canHideStep(2)}>
        <PartnershipSectionHeader
          step={2}
          title='Cobrança'
          topics={['Fatura', 'Cobrança antecipada']}
        />
        <PartnershipSectionContainer>
          <InvoicePolicy
            invoicePolicy={invoicePolicy}
            invoicePolicyErrors={errors?.invoicePolicy || {}}
            isGroupEditable={isGroupEditable}
            isLoading={isLoading}
            isFormDisabled={isLoading}
            formUtils={formUtils}
            submitCount={formState.submitCount}
          />
        </PartnershipSectionContainer>
      </PartnershipSection>

      <PartnershipSection canHide={canHideStep(3)}>
        <PartnershipSectionHeader
          step={3}
          title='Parceiros'
          topics={['Parceiros']}
        />
        <PartnershipSectionContainer>
          {!canMinifyFormLayout && <PaginatedSellersProvider>
            <PartnershipForm
              tradeAgreementName={tradeAgreement?.name}
              partnershipList={comercialPartners}
              formUtils={formUtils}
              isDisabled={isLoading}
            />
          </PaginatedSellersProvider>}
        </PartnershipSectionContainer>
      </PartnershipSection>
    </div>
  )
}
