import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { useLocalitiesForm } from 'hooks/domains/useLocalitiesForm'
import { array, string } from 'yup'

const MyDayFilterSchema = {
  passengerName: string(),
  ticketCode: string(),
  servicesId: array().of(string()),
  partnerId: string(),
  date: string().required('Preencha a data'),
  providerId: array().of(string()),
  sellerId: array()
    .of(string())
    .when('providerId', (providerId, schema) => {
      if (providerId.length > 0) {
        return array().of(string()).max(0, 'Você pode só filtrar por fornecedor ou vendedor')
      }

      return schema
    }),
  ticketStatus: array().of(string()),
  countries: array().of(string()),
  states: array().of(string()),
  cities: array().of(string())
}

export const useMyDayFilters = ({
  initialFilters
}) => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange }
  } = useForm(initialFilters, MyDayFilterSchema)

  const localitiesData = useLocalitiesForm({
    form,
    onInputChange
  })

  const {
    onChangeCities = identity,
    onChangeCountries = identity,
    onChangeStates = identity
  } = localitiesData

  return {
    onInputChange,
    form,
    errors,
    onSubmit,
    onChangeCities,
    onChangeCountries,
    onChangeStates,
    localitiesData
  }
}
