/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useMemo } from 'react'

import { identity } from '@bonitour/common-functions'

import { PartnershipGroupForm, partnershipGroupInviteSchema } from 'app/PartnershipGroup/form'
import { usePartnershipGroupForm } from 'app/PartnershipGroup/hooks/usePartnershipGroupForm'
import { Button, GhostButton, Card } from '@bonitour/components'

import { stringifyObject } from 'utils/object'

import { ctaPadding, ghostStyling, buttonsContainer, headerContainer, ghostButtonDisabled } from './PartnershipInvite.style'

export const PartnershipInvite = ({
  onAcceptance = identity,
  onRefusal = identity,
  inviteInformation = {},
  isLoadingSomeAction = false
}) => {
  const initialData = useMemo(() => {
    const inviteKeys = Object.keys(inviteInformation)
    if (inviteKeys.length) {
      const stringifiedKeys = stringifyObject(inviteInformation)
      return stringifiedKeys
    }
    return {}
  }, [inviteInformation])

  const {
    formUtils,
    updatedFormState
  } = usePartnershipGroupForm(partnershipGroupInviteSchema, initialData)

  return (
    <Card>
      <div css={headerContainer}>
        <div css={buttonsContainer}>
          <GhostButton
            onClick={onRefusal}
            disabled={isLoadingSomeAction}
            css={[ghostStyling, ghostButtonDisabled]}>Recusar</GhostButton>
          <Button onClick={onAcceptance} disabled={isLoadingSomeAction} css={ctaPadding}>Aceitar</Button>
        </div>
      </div>
      <PartnershipGroupForm
        canDisableAllForm={true}
        allowedSteps={[1, 2]}
        formState={updatedFormState}
        formUtils={formUtils}
        isLoading={isLoadingSomeAction}
      />
    </Card>
  )
}
