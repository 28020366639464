import { css, keyframes } from '@emotion/core'
import { margin } from 'assets/styles/global'
import { BREAK_POINTS, colors } from '@bonitour/components'

const margin10 = margin(10, 10, 10, 10)

export const partnetCard = css`
  position: relative;
  ${margin10};
  padding: 20px;
  border: 1px solid ${colors.gray8};
  width: 100%;
  max-width: 210px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    max-width: 100%;
  }
`

export const partnerCheckbox = css`
  position: absolute;
  top: 10px;
  left: 10px;
`
export const paginationContainer = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  justify-items: center;

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    gap: 20px;
  }
`

const gradientAnimation = keyframes`
  100% {
    transform: translateX(100%);
  }
`

export const loadingListContainer = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  justify-items: center;

  width: 100%;
  &,
  & > li {
    box-sizing: border-box;
  }
  li {
    content: '';
    border: 1px solid ${colors.gray7};
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    background-color: ${colors.white};
    border-radius: 10px;
    width: 210px;
    height: 207px;
    margin: 10px;
    padding: 20px;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(240, 240, 240, 0) 0,
        rgba(240, 240, 240, 0.25) 15%,
        rgba(240, 240, 240, 0.75) 80%,
        rgba(240, 240, 240, 0)
      );
      animation: ${gradientAnimation} 2s infinite;
      content: '';
    }
  }

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    justify-items: center;
    gap: 20px;
  }
`

export const searchInput = css`
  width: 27.5rem;
  margin-right: 0.625rem;
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    width: 100%;
    min-width: 7.5rem;
  }
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    margin: 0;
  }
`

export const wrapperSearchInput = css`
  display: flex;
  justify-content: end;
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    width: 100%;
  }
`

export const searchIcon = css`
  font-size: 20px;
`

export const notSelectable = css`
  user-select: none;
`

export const partnershipBarResizes = css`
  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    > span:first-of-type {
      margin-bottom: 1rem;
    }
  }
`
