import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { colors } from '@bonitour/components'
import { EXPERIENCE_VACANCY_TYPE_OPACITY, EXPERIENCE_VACANCY_BOX_SHADOW } from 'constants/bookingMap'

import { hexToRgba } from 'utils/hexToRgba'

export const MinifiedCombinedExperienceCell = styled.div`
  padding: 0.25rem;
  border-radius: 0.35rem;
  background-color: ${({ bgOpacity, bgColor }) => bgColor ? hexToRgba(bgColor, bgOpacity) : 'transparent'};
  color: ${({ bgOpacity }) => bgOpacity === EXPERIENCE_VACANCY_TYPE_OPACITY.reservationsTotal ? colors.white : colors.gray2};
  width: 2.5rem;
  text-align: center;
  cursor: default;

  &.can-margin {
    &:has(+ .absolute-on-middle),
    .absolute-on-middle + & {
      span {
        &.left {
          margin-right: 0.30rem;
        }
        &.right {
          margin-left: 0.30rem;
        }
      }
    }
  }

  &.color-as-bg-color {
    background-color: transparent;
    color: ${({ bgColor }) => bgColor};
    width: auto;
  }

  &.border-as-bg-color div {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
    box-shadow: ${EXPERIENCE_VACANCY_BOX_SHADOW} ${({ bgColor }) => hexToRgba(bgColor, EXPERIENCE_VACANCY_TYPE_OPACITY.bookingNow)};
    z-index: 1;
  }

  &.absolute-on-left {
    position: absolute;
    left: -4rem;
    width: 3.5rem;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.draw-text-shadow {
    -webkit-text-stroke: 2px ${hexToRgba(colors.black, 0.25)};
    paint-order: stroke fill;
    font-weight: bolder;
    &-light {
      -webkit-text-stroke: 2px ${hexToRgba(colors.white, 0.4)};
      paint-order: stroke fill;
    }
  }

  &.text-bold {
    font-weight: bold;
  }

  &.clickable {
    user-select: none;
    cursor: pointer;
  }

  &.absolute-on-middle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${colors.white};
    font-weight: bolder;
    width: 1.25rem;
    top: 2px;
    bottom: 2px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  }

  &.hoverable {
    &:hover {
      span {
        position: relative;
        z-index: 5;
      }
    }
  }
`

export const ActivityRow = styled.div`
  display: inline-flex;
  gap: 0.15rem;
  position: relative;
`

export const gap = css`
  gap: 0.5rem;
`
