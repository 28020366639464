import { useCallback, useEffect, useMemo, useState } from 'react'

import { useParams, useHistory } from 'react-router-dom'

import { useActivity } from 'contexts/Activity'

import { InvitesService } from 'services/Invites/Service'
import { useToast } from '@bonitour/components'

const INITIAL_LOADING_STATE = {
  refusalInvite: false,
  acceptanceInvite: false,
  getInvite: false
}

const INITIAL_RESPONSE_STATE = {
  getInvite: {}
}

const DEFAULT_THROW_ERROR_MESSAGE = 'Ocorreu um erro inesperado, tente novamente'

export const usePartnershipInvite = () => {
  const [response, setResponse] = useState(INITIAL_RESPONSE_STATE)
  const [isLoading, setIsLoading] = useState(INITIAL_LOADING_STATE)

  const { updateList } = useActivity()
  const { add: addToast } = useToast()
  const { inviteId } = useParams()
  const history = useHistory()

  const isLoadingSomeAction = useMemo(() => Object.values(isLoading).some(Boolean), [isLoading])

  const backToProviders = useCallback(() => history.push('/providers'), [history])

  const safeOnBackClick = useCallback(() => {
    if (isLoadingSomeAction) return
    backToProviders()
  }, [backToProviders, isLoadingSomeAction])

  const toggleIsLoading = useCallback((method = '') => setIsLoading(previousState => ({ ...previousState, [method]: !previousState[method] })), [])

  const fillResponse = useCallback((method = '') => (data) => {
    setResponse(previousState => ({ ...previousState, [method]: data }))
  }, [])

  const onAcceptanceInvite = useCallback(async () => {
    toggleIsLoading('acceptanceInvite')
    if (isLoading.acceptanceInvite) return
    return InvitesService.respond(inviteId)
      .then(() => {
        addToast('Convite aceito', 'success')
        updateList(true)
        backToProviders()
      })
      .catch(() => addToast(DEFAULT_THROW_ERROR_MESSAGE))
      .finally(() => toggleIsLoading('acceptanceInvite'))
  }, [toggleIsLoading, isLoading.acceptanceInvite, inviteId, addToast, updateList, backToProviders])

  const onRefusalInvite = useCallback(async () => {
    toggleIsLoading('refusalInvite')
    if (isLoading.refusalInvite) return
    return InvitesService.respond(inviteId, false)
      .then(() => {
        addToast('Convite rejeitado', 'success')
        updateList(true)
        backToProviders()
      })
      .catch(() => {
        addToast(DEFAULT_THROW_ERROR_MESSAGE)
      })
      .finally(() => toggleIsLoading('refusalInvite'))
  }, [toggleIsLoading, isLoading.refusalInvite, inviteId, addToast, updateList, backToProviders])

  const getInvite = useCallback(() => {
    toggleIsLoading('getInvite')
    if (isLoading.getInvite) return
    InvitesService.get(inviteId)
      .then(fillResponse('getInvite'))
      .then(invite => {
        if (invite.answered) {
          addToast('Convite já respondido', 'warning')
          backToProviders()
        }
      })
      .finally(() => toggleIsLoading('getInvite'))
  }, [toggleIsLoading, isLoading.getInvite, inviteId, fillResponse, addToast, backToProviders])

  useEffect(() => {
    getInvite()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteId])

  return {
    onRefusalInvite,
    onAcceptanceInvite,
    inviteInformation: response.getInvite,
    onBackClick: safeOnBackClick,
    isLoadingSomeAction
  }
}
