/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { memo } from 'react'

import { flex, flexRow } from '@bonitour/components'

import { EXPERIENCE_VACANCY_TYPE_OPACITY } from 'constants/bookingMap'

import { hidden, positionRelative } from 'assets/styles/global'
import { ActivityRow, MinifiedCombinedExperienceCell, gap } from './combinedExperienceSummaryCellGroup.styles'
import { minutesToText, timeToMinutes } from 'utils/time'

const ExperienceSummaryCellGroup = ({
  color,
  isFirstDateOfHour,
  duration,
  vacancyTotals = {},
  isBlockedSummary = false,
  children
}) => {
  const { totalAvailable, reservationsTotal, selectedTotals } = vacancyTotals

  return (
    <ActivityRow>
      {isFirstDateOfHour && (<>
        <MinifiedCombinedExperienceCell
          className='color-as-bg-color absolute-on-left text-bold'
          bgColor={color}
        >
          {minutesToText(timeToMinutes(duration))}
        </MinifiedCombinedExperienceCell>
      </>)}
      <div
        css={[flex, flexRow, positionRelative, gap, isBlockedSummary && hidden]}>
        <MinifiedCombinedExperienceCell
          bgColor={color}
          className={'draw-text-shadow-light clickable hoverable can-margin'}
          css={[isBlockedSummary && hidden]}
          bgOpacity={EXPERIENCE_VACANCY_TYPE_OPACITY.totalAvailable}
        >
          <span className='left'>{totalAvailable}</span>
        </MinifiedCombinedExperienceCell>
        {Boolean(selectedTotals) && (
          <MinifiedCombinedExperienceCell
            bgColor={color}
            className='color-as-bg-color border-as-bg-color absolute-on-middle'
            css={[isBlockedSummary && hidden]}
          >
            <div>{selectedTotals}</div>
          </MinifiedCombinedExperienceCell>
        )}
        <MinifiedCombinedExperienceCell
          bgColor={color}
          className={'draw-text-shadow hoverable can-margin'}
          css={[isBlockedSummary && hidden]}
          bgOpacity={EXPERIENCE_VACANCY_TYPE_OPACITY.reservationsTotal}
        >
          <span className='right'>{reservationsTotal}</span>
        </MinifiedCombinedExperienceCell>
      </div>
      {isBlockedSummary ? children : ''}
    </ActivityRow>
  )
}

export const CombinedExperienceSummaryCellGroup = memo(ExperienceSummaryCellGroup)
