import { identity } from '@bonitour/common-functions'
import { useToast } from '@bonitour/components'
import { useMemo, useCallback } from 'react'
import { useQuery } from 'react-query'
import { CityApi } from 'services/Localities/CityApi'
import { CountryApi } from 'services/Localities/CountryApi'
import { StateApi } from 'services/Localities/StateApi'

/**
 * One id of a locality have 36 characters
 * 36 chars * 54 localities = 1944 chars
 * The safe quantity of characters at URL is 2000 (determined by a senior)
 */
const limitOfLocalities = 54
const STALE_TIME = 60 * 60 * 1000 * 24 // 24 hours

export const useLocalitiesForm = ({
  onInputChange = identity,
  form = {}
}) => {
  const { add: addToast } = useToast()

  const {
    countries = [],
    states = [],
    cities = []
  } = form

  const {
    isSelectedManyCountries,
    isSelectedManyStates,
    isSelectedManyCities,
    isEmptySelectedCountries,
    isEmptySelectedStates
  } = useMemo(() => ({
    isSelectedManyCountries: countries.length > 1,
    isSelectedManyStates: states.length > 1,
    isSelectedManyCities: cities.length > 1,
    isEmptySelectedCountries: countries.length === 0,
    isEmptySelectedStates: states.length === 0
  }), [countries, states, cities])

  const {
    data: countriesData,
    isLoading: isLoadingCountries
  } = useQuery({
    staleTime: STALE_TIME,
    queryKey: 'localities',
    queryFn: CountryApi.list
  })

  const {
    data: statesData,
    isLoading: isLoadingStates
  } = useQuery({
    queryKey: ['localities', JSON.stringify(countries), isSelectedManyCountries],
    initialData: [],
    queryFn: () => {
      if (isSelectedManyCountries || countries.length === 0) return []
      return StateApi.list(countries[0])
    }
  })

  const {
    data: citiesData,
    isLoading: isLoadingCities
  } = useQuery({
    queryKey: ['localities', JSON.stringify(states)],
    initialData: [],
    queryFn: () => {
      if (!states.length) return []
      const promises = states
        .map((stateId) => CityApi.list(countries[0], stateId))
      return Promise
        .all(promises)
        .then((cities) => cities.flat())
    }
  })

  const {
    isEmptyCountriesData,
    isEmptyStatesData,
    isEmptyCitiesData
  } = useMemo(() => ({
    isEmptyCountriesData: !countriesData?.length && !isLoadingCountries,
    isEmptyStatesData: !statesData?.length && !isLoadingStates,
    isEmptyCitiesData: !citiesData?.length && !isLoadingCities
  }), [countriesData?.length, isLoadingCountries, statesData?.length, isLoadingStates, citiesData?.length, isLoadingCities])

  const {
    countriesMultiSelectPlaceholder,
    statesMultiSelectPlaceholder,
    citiesMultiSelectPlaceholder
  } = useMemo(() => ({
    countriesMultiSelectPlaceholder: isLoadingCountries ? 'Carregando países' : isEmptyCountriesData ? 'Nenhum país encontrado' : 'Selecione um país',
    statesMultiSelectPlaceholder: isLoadingStates ? 'Carregando estados' : (isEmptyStatesData || isSelectedManyCountries) ? 'Nenhum estado disponível' : 'Selecione um estado',
    citiesMultiSelectPlaceholder: isLoadingCities ? 'Carregando cidades' : (isEmptyCitiesData || isSelectedManyStates || isSelectedManyCountries) ? 'Nenhuma cidade disponível' : 'Selecione uma cidade'
  }), [isEmptyCitiesData, isEmptyCountriesData, isEmptyStatesData, isLoadingCities, isLoadingCountries, isLoadingStates, isSelectedManyCountries, isSelectedManyStates])

  const checkCanAddLocality = useCallback(({
    newCities = cities,
    newStates = states,
    newCountries = countries,
    willRemoveLocalities = false
  }) => {
    if (willRemoveLocalities) {
      return true
    }

    const canAddLocality = (newCities.length + newStates.length + newCountries.length) <= limitOfLocalities

    if (!canAddLocality) {
      addToast(`Você pode selecionar no máximo ${limitOfLocalities} localidades`)
      return false
    }

    return true
  }, [addToast, cities, countries, states])

  const onChangeCountries = useCallback((newCountries = []) => {
    const willSelectManyCountries = newCountries.length > 1
    const willRemoveAllCountries = newCountries.length === 0
    const canRemoveLocalities = (willSelectManyCountries || willRemoveAllCountries) && (states.length || cities.length)

    if (canRemoveLocalities) {
      onInputChange('states')([])
      onInputChange('cities')([])
    }

    if (!checkCanAddLocality({ newCountries, willRemoveLocalities: canRemoveLocalities })) {
      return
    }

    onInputChange('countries')(newCountries)
  }, [checkCanAddLocality, cities.length, onInputChange, states.length])

  const onChangeStates = useCallback((newStates = []) => {
    const willSelectManyStates = newStates.length > 1
    const willRemoveAllStates = newStates.length === 0
    const canRemoveCities = (willSelectManyStates || willRemoveAllStates) && cities.length

    if (canRemoveCities) {
      onInputChange('cities')([])
    }

    if (!checkCanAddLocality({ newStates, willRemoveLocalities: canRemoveCities })) {
      return
    }

    onInputChange('states')(newStates)
  }, [checkCanAddLocality, cities.length, onInputChange])

  const onChangeCities = useCallback((newCities = []) => {
    const willSelectManyCities = newCities.length > 1

    if (!checkCanAddLocality({ newCities })) {
      return
    }

    if (isSelectedManyCountries && isSelectedManyStates && willSelectManyCities) {
      addToast('Você pode selecionar apenas uma cidade')
      return
    }

    onInputChange('cities')(newCities)
  }, [addToast, checkCanAddLocality, isSelectedManyCountries, isSelectedManyStates, onInputChange])

  return {
    onChangeCountries,
    onChangeStates,
    onChangeCities,
    isEmptyCitiesData,
    isEmptyCountriesData,
    isEmptyStatesData,
    isEmptySelectedCountries,
    isEmptySelectedStates,
    isSelectedManyCities,
    isSelectedManyCountries,
    isSelectedManyStates,
    placeholders: {
      cities: citiesMultiSelectPlaceholder,
      countries: countriesMultiSelectPlaceholder,
      states: statesMultiSelectPlaceholder
    },
    isLoading: {
      countries: isLoadingCountries,
      states: isLoadingStates,
      cities: isLoadingCities
    },
    options: {
      citiesOptions: citiesData,
      countriesOptions: countriesData,
      statesOptions: statesData
    }
  }
}
