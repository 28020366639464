/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import { HeaderPage, Button, Card, useToast } from '@bonitour/components'

import { createPartnershipGroupSchema, PartnershipGroupForm } from './form'

import { useSellersGroup } from './hooks/useSellersGroup'
import { usePartnershipGroupForm } from './hooks/usePartnershipGroupForm'

import { fullWSize, positionRelative } from 'assets/styles/global'
import { PartnershipCounter } from 'containers/PartnershipGroup/Partnership/components'

const INITIAL_DATA = {
  'invoicePolicy.invoiced': false,
  'invoicePolicy.deadline': false
}

export const CreatePartnershipGroup = () => {
  const { add: addToast } = useToast()
  const { push } = useHistory()

  const backToDashboard = useCallback(() => push('/sellers'), [push])

  const {
    create,
    isLoadingCreation
  } = useSellersGroup()

  const {
    handleSubmit,
    updatedFormState,
    formUtils
  } = usePartnershipGroupForm(createPartnershipGroupSchema, INITIAL_DATA)

  const onSuccessValidation = useCallback((creationPayload) => {
    return create(creationPayload)
      .then(backToDashboard)
  }, [create, backToDashboard])

  const onFailedValidation = useCallback(() => {
    addToast('Confira o formulário e tente novamente')
  }, [addToast])

  const onSubmit = handleSubmit(onSuccessValidation, onFailedValidation)

  return (
    <>
      <HeaderPage onBack={backToDashboard} title="Grupo" />
      <Card>
        <div>
          <PartnershipGroupForm
            isGroupEditable
            formState={updatedFormState}
            isLoading={isLoadingCreation}
            formUtils={formUtils}
          />

          <Button
            css={[fullWSize, positionRelative]}
            disabled={isLoadingCreation}
            onClick={onSubmit}>
            {isLoadingCreation ? 'Criando grupo' : 'Criar grupo'}
            <PartnershipCounter selectedQuantity={updatedFormState.data.comercialPartners?.length} />
          </Button>
        </div>
      </Card>
    </>
  )
}
