/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useDebounce } from '@bonitour/app-functions'
import { PaginatedSellersContext } from 'app/Sellers/SellersContext'
import { useContext, useEffect, useMemo, useState } from 'react'

import { searchInput, searchIcon, wrapperSearchInput } from './PartnershipForm.style'
import { InputWithSuffix, SearchIcon } from '@bonitour/components'

export const PartnershipSearchComponent = ({ isDisabled = false }) => {
  const [search, setSearch] = useState('')
  const { handleSearch, toSearch } = useContext(PaginatedSellersContext)

  const debouncedSearch = useDebounce(search, 1500)

  const memoizedSearch = useMemo(() => toSearch, [toSearch])

  useEffect(() => {
    if (debouncedSearch === memoizedSearch) return
    handleSearch(debouncedSearch)
  }, [debouncedSearch, handleSearch, memoizedSearch])

  return (
    <div css={wrapperSearchInput}>
      <InputWithSuffix type="text" css={searchInput} onChange={setSearch} value={search} placeholder="Buscar parceiros" disabled={isDisabled}>
        <SearchIcon css={searchIcon} />
      </InputWithSuffix>
    </div>
  )
}
