import { useToast } from '@bonitour/components'
import { useBack } from 'hooks/useBack'
import { useCallback, useState } from 'react'
import { SellersGroupsService } from 'services/SellersGroups/Service'

export const usePartnershipGroup = () => {
  const {
    onSafeBack,
    goTo
  } = useBack('/providers')

  const {
    add: addToast
  } = useToast()

  const [groupData, setGroupData] = useState(null)
  const [isLoadingFetch, setIsLoadingFetch] = useState(false)

  const fetchGroup = useCallback(async (groupId) => {
    if (isLoadingFetch) return
    setIsLoadingFetch(true)
    return SellersGroupsService.getById(groupId)
      .then(setGroupData)
      .catch(() => {
        onSafeBack()
        addToast('Grupo não encontrado')
      })
      .finally(() => setIsLoadingFetch(false))
  }, [addToast, isLoadingFetch, onSafeBack])

  return {
    fetchGroup,
    groupData,
    isLoadingFetch,
    onSafeBack: () => goTo('/providers')
  }
}
