/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useCallback, useMemo } from 'react'

import { Controller } from 'react-hook-form'

import { ToggleInputGroup, P, RadioInputGroup, RadioGroup, Input } from '@bonitour/components'
import { PartnershipGroupError } from '../components'

import { INVOICE_DEADLINE_TYPES } from 'constants/invoiceDeadline'

import { hidden } from 'assets/styles/global'
import { grayColor, horinzontalMargin10, width110, line, radioGroup, marginBottom20, moreContentTooltip, radioErrorTheme } from './InvoicePolicy.style'

export const InvoiceDeadline = ({
  formUtils = {},
  deadline = 0,
  deadlineType = '',
  isFormDisabled = false,
  isLoading = false,
  invoicePolicyErrors = {},
  submitCount = 0
}) => {
  const {
    onChange: emitOnChange,
    clearErrors: emitClearErrors,
    control: formControl,
    trigger: emitTrigger
  } = formUtils

  const onChangeDeadline = useCallback((value) => {
    if (value < 1) return
    emitOnChange('invoicePolicy.deadline')(value)
    if (value) {
      emitClearErrors('invoicePolicy.deadline')
    }
  }, [emitClearErrors, emitOnChange])

  const onToggleDeadline = useCallback((setterFn, arg) => {
    setterFn(arg)
    if (submitCount > 0) {
      emitTrigger('invoicePolicy.deadlineType')
    }
  }, [emitTrigger, submitCount])

  const hasDeadlineTypeError = useMemo(() => Boolean(invoicePolicyErrors?.deadlineType?.message), [invoicePolicyErrors?.deadlineType])
  const hasDeadline = useMemo(() => deadline > 0, [deadline])

  const {
    isSelectedBeforeSlot,
    isSelectedAfterCreation
  } = useMemo(() => ({
    isSelectedBeforeSlot: deadlineType === INVOICE_DEADLINE_TYPES.BEFORE_SLOT,
    isSelectedAfterCreation: deadlineType === INVOICE_DEADLINE_TYPES.AFTER_CREATION
  }), [deadlineType])

  const {
    pluralizedCharAfterCreationLabel,
    pluralizedCharBeforeSlotLabel
  } = useMemo(() => ({
    pluralizedCharBeforeSlotLabel: isSelectedBeforeSlot && deadline > 1 ? 's' : '',
    pluralizedCharAfterCreationLabel: isSelectedAfterCreation && deadline > 1 ? 's' : ''
  }), [isSelectedBeforeSlot, isSelectedAfterCreation, deadline])

  return (
    <div>
      <div css={[marginBottom20, isFormDisabled && hidden]}>
        <PartnershipGroupError error={invoicePolicyErrors?.deadline} customCss={[moreContentTooltip]} isTooltip={false}>
          <Controller
            control={formControl}
            name='invoicePolicy.deadline'
            render={({
              field: { onChange, ref, onBlur, value }
            }) => (
              <ToggleInputGroup
                disabled={isFormDisabled || isLoading}
                onChange={isChecked => onToggleDeadline(onChange, Number(isChecked))}
                onBlur={onBlur}
                ref={ref}
                canEmitToggleEventOfRow={true}
                checked={value}
              >
                Utilizar deadline
              </ToggleInputGroup>
            )}/>
        </PartnershipGroupError>
      </div>
      <P css={[!(isFormDisabled && deadline === 0) && hidden]}>
        Não há deadline
      </P>
      <PartnershipGroupError error={invoicePolicyErrors?.deadlineType} isTooltip={false} canShowOnBottom isVisible={hasDeadline}>
        <div css={[line, !hasDeadline && hidden]}>
          <Controller
            control={formControl}
            name={'invoicePolicy.deadlineType'}
            render={({ field: { onChange, onBlur, ref, value } }) => (
              <RadioGroup css={radioGroup} value={value} onChange={onChange} disabled={isFormDisabled || isLoading} onBlur={onBlur} ref={ref}>
                <RadioInputGroup value={INVOICE_DEADLINE_TYPES.BEFORE_SLOT} customCss={[hasDeadlineTypeError && radioErrorTheme]}>
                  <span css={grayColor}>O pagamento deverá ser feito em até</span>
                  <Input
                    type='number'
                    min='1'
                    customCss={[horinzontalMargin10, width110]}
                    disabled={!isSelectedBeforeSlot || isFormDisabled || isLoading}
                    onChange={onChangeDeadline}
                    value={isSelectedBeforeSlot ? deadline : 0}
                  />
                  <span css={grayColor}>{`dia${pluralizedCharBeforeSlotLabel} antes da execução da experiência`}</span>
                </RadioInputGroup>
                <RadioInputGroup value={INVOICE_DEADLINE_TYPES.AFTER_CREATION} customCss={[hasDeadlineTypeError && radioErrorTheme]}>
                  <span css={grayColor}>O pagamento deverá ser feito em até</span>
                  <Input
                    type='number'
                    min='1'
                    customCss={[horinzontalMargin10, width110]}
                    disabled={!isSelectedAfterCreation || isFormDisabled || isLoading}
                    onChange={onChangeDeadline}
                    value={isSelectedAfterCreation ? deadline : 0}
                  />
                  <span css={grayColor}>{`dia${pluralizedCharAfterCreationLabel} após efetuar a reserva`}</span>
                </RadioInputGroup>
              </RadioGroup>
            )}
          />
        </div>
      </PartnershipGroupError>
    </div>
  )
}
