import { useCallback, useState } from 'react'

import { useToast } from '@bonitour/components'

import { SellersGroupsService } from 'services/SellersGroups/Service'
import { usePermissions } from 'hooks/usePermissions'

const DEFAULT_LOADING_STATE = {
  edition: false,
  visualization: false,
  creation: false
}

const DEFAULT_RESPONSE_STATE = {
  visualization: null,
  creation: null
}

export const useSellersGroup = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()

  const [isLoading, setIsLoading] = useState(DEFAULT_LOADING_STATE)
  const [response, setResponse] = useState(DEFAULT_RESPONSE_STATE)

  const fillResponse = useCallback((method = '') => (data) => setResponse(previousState => ({ ...previousState, [method]: data })), [])

  const toggleIsLoading = useCallback((method = '') => setIsLoading(previousState => ({ ...previousState, [method]: !previousState[method] })), [])

  const edit = useCallback((sellersGroup) => {
    toggleIsLoading('edition')
    return SellersGroupsService
      .update(sellersGroup)
      .then(() => {
        addToast('Grupo editado com sucesso. Aguarde confirmação de seu(s) parceiro(s), se necessário', 'success')
      })
      .catch(() => addToast('Houve um erro na edição do grupo'))
      .finally(() => toggleIsLoading('edition'))
  }, [addToast, toggleIsLoading])

  const get = useCallback((groupId) => {
    if (isLoading.visualization) return
    toggleIsLoading('visualization')
    return SellersGroupsService.getById(groupId)
      .then(fillResponse('visualization'))
      .catch(() => 'Grupo não encontrado.')
      .finally(() => toggleIsLoading('visualization'))
  }, [fillResponse, isLoading.visualization, toggleIsLoading])

  const create = useCallback((creationPayload = {}) => {
    if (isLoading.creation) return
    toggleIsLoading('creation')
    return SellersGroupsService.create(creationPayload)
      .then(fillResponse('creation'))
      .then(() => addToast('Grupo criado com sucesso. Aguarde confirmação de seu(s) parceiro(s)', 'success'))
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de criação de grupos')
        hasPermission && addToast('Houve um erro na criação do grupo')
      })
      .finally(() => toggleIsLoading('creation'))
  }, [addToast, fillResponse, handlePermission, isLoading.creation, toggleIsLoading])

  return {
    edit,
    get,
    create,
    isLoadingVisualization: isLoading.visualization,
    isLoadingEdition: isLoading.edition,
    isLoadingCreation: isLoading.creation,
    visualizationResponse: response.visualization,
    creationResponse: response.creation
  }
}
